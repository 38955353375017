import React, { createContext, useContext, useReducer } from "react";
import { FormikProps, FormikState, useFormik } from "formik";
import HomePage from "../pages/home/HomePage";
import { initModalOtp, initOtpForm } from "../data/initialize/otp.data";
import { useComponentInfo } from "../hooks/useComponentInfo";
import { otpFormSchema } from "../validations/otpFormValidation";
import useOtpApi from "../api/useOtpApi";
import ResponseStatus from "../types/ResponseStatus";
import translateErrorCode from "../helpers/errors/translateErrorCode";
import { convertFileToSendToServer } from "../helpers/convertors";
import { useProjectConfigContext } from "./ProjectConfigContext";
import { AlertColors } from "../types/colors/alertColors";
import { IModalOtp, IOtpForm } from "../interface/modalOtp.interface";
import { OtpModalActionKind, otpModalReduser } from "../reducers/otpModalReduser";
import { ActionPortalTypes } from "../types/ActionPortalTypes";
import { IComponentInfo } from "../interface/componentInfo.interface";
import { IFileDataBase64 } from "../interface/fileDataBase64.interface";

type HomePageContextType = {
    modalOtpState: IModalOtp, 
    closeOtpModal: () => void, 
    openOtpModal: (modalTitle: string, portalAction: ActionPortalTypes) => void, 
    handleCheckOtpCode: () => Promise<void>, 
    sendOtpCode: () => Promise<void>, 
    resetOtpForm: (nextState?: Partial<FormikState<IOtpForm>>) => void, 
    componentInfoPage: IComponentInfo, 
    componentInfoModal: IComponentInfo,
    handleAddFileOtp: (file: IFileDataBase64) => Promise<void>,
    Formik: FormikProps<IOtpForm>
}

export const HomePageContext = createContext<HomePageContextType | undefined>(undefined);

export const HomePageProvider = () => {

    const { loadProjectConfig } = useProjectConfigContext();
    const [modalOtpState, despatchModalOtp] = useReducer(otpModalReduser, initModalOtp);
    
    const {componentInfo: componentInfoPage, loading: loadingPage, 
        notLoading: notLoadingPage, setDangerAlert: setDangerAlertPage, setAlert: setAlertPage} = useComponentInfo();

    const {componentInfo: componentInfoModal, loading: loadingModal, 
        notLoading: notLoadingModal, setDangerAlert: setDangerAlertModal} = useComponentInfo();

    const {sendOtpCodeApi, checkOtpCodeApi, cancelPermitOtpApi, 
        checkPermitValidationDateOtpApi, checkRequestStatusOtpApi, addFileToRequestOtpApi} = useOtpApi();
    
    const Formik = useFormik<IOtpForm>({
        initialValues: initOtpForm,
        validateOnMount: true,
        validateOnChange: false,  
        validationSchema: otpFormSchema,
        onSubmit: () => {}
    });

    const {values, resetForm} = Formik;

    function openOtpModal(modalTitle, portalAction) {

        despatchModalOtp({
            type: OtpModalActionKind.OPEN_MODAL,
            payload: {
                modalTitle: modalTitle,
                portalAction: portalAction,
            }
        });
    }

    function closeOtpModal() {
  
        resetOtpForm();
        
        despatchModalOtp({
            type: OtpModalActionKind.CLOSE_MODAL 
        });
    }

    async function handleCheckOtpCode() {
        loadingModal();
        
        let message = '';

        try {

            if(modalOtpState.portalAction === ActionPortalTypes.ADD_FILE_TO_REQUEST) {
               
                await checkOtpCode(false);
            }else if(modalOtpState.portalAction === ActionPortalTypes.CHECK_REQUEST_STATUS) {
                
                await checkRequestStatusOtp();
            }else if(modalOtpState.portalAction === ActionPortalTypes.CANCEL_PERMIT) {
                
                await cancelPermitOtp();
            }else if(modalOtpState.portalAction === ActionPortalTypes.CHECK_PEMIT_VALIDITY) {
                
                await checkPermitValidationDateOtp();
            }
        
            notLoadingModal();

        } catch (error) {
            console.log('Error', error);
            if (error.response) {

                message = translateErrorCode(error);
            } else if (error.request) {
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

            setDangerAlertModal(message);
        }
    }

    async function checkPermitValidationDateOtp() {

        const dataToSend = {
            personId: values.personId, 
            vehicleNum: values.vehicleNum, 
            otpCode: values.otpCode,
        };
        
        const response = await checkPermitValidationDateOtpApi(dataToSend);
     
        if(!response?.status || response.status !== ResponseStatus.SUCCESS || !response.data?.dateValidationPeriod) {

            throw new Error(response?.message ? response.message : 'בעיה בשרת - לא החזיר תשובה');
        }

        despatchModalOtp({
            type: OtpModalActionKind.OTP_SUCCESS,
            payload: {
                name: 'permitDateValidation', 
                value: response.data.dateValidationPeriod
            } 
        });
        
    }

    async function checkRequestStatusOtp() {
        
        const dataToSend = {
            personId: values.personId, 
            vehicleNum: values.vehicleNum, 
            otpCode: values.otpCode,
        };
        
        const response = await checkRequestStatusOtpApi(dataToSend);
        
        if(!response?.status || response.status !== ResponseStatus.SUCCESS || !response.data?.requestStatus) {
            
            throw new Error(response?.message ? response.message : 'בעיה בשרת - לא החזיר תשובה');
        }

        despatchModalOtp({
            type: OtpModalActionKind.OTP_SUCCESS,
            payload: {
                name: 'requestStatus', 
                value: response.data.requestStatus
            }
        });

        return true;
    }

    async function cancelPermitOtp() {

        const dataToSend = {
            personId: values.personId, 
            vehicleNum: values.vehicleNum, 
            otpCode: values.otpCode,
        };
        
        const response = await cancelPermitOtpApi(dataToSend);
        
        if(!response?.status || response.status !== ResponseStatus.SUCCESS) {

            throw new Error(response?.message ? response.message : 'בעיה בשרת - לא החזיר תשובה');
        }
    
        if(!response.data?.permitIsCanceled || response.data.permitIsCanceled !== true) {
            throw new Error('תהליך ביטול התו נכשל');
        }

        despatchModalOtp({
            type: OtpModalActionKind.OTP_SUCCESS,
            payload:{
                name: 'isPermitCanceled', 
                value: 'תו בוטל בהצלחה'
            } 
        });
    }

    async function checkOtpCode(isPermit: boolean) {

        const dataToSend = { 
            isPermit: isPermit,
            personId: values.personId, 
            vehicleNum: values.vehicleNum, 
            otpCode: values.otpCode,
        };
        
        const response = await checkOtpCodeApi(dataToSend);
        
        if(!response?.status || response.status !== ResponseStatus.SUCCESS) {

            throw new Error(response?.message ? response.message : 'בעיה בשרת - לא החזיר תשובה');
        }
    
        if(!response.data?.isOtpCodeValid || response.data.isOtpCodeValid !== true) {
            throw new Error('אימות נכשל');
        }

        despatchModalOtp({
            type: OtpModalActionKind.OTP_SUCCESS_FILE
        });
    
    }

    async function sendOtpCode() {

        loadingModal();
        let message = '';
        let success = false;

        try {

            let isPermit = true;

            if(modalOtpState.portalAction === ActionPortalTypes.ADD_FILE_TO_REQUEST || modalOtpState.portalAction === ActionPortalTypes.CHECK_REQUEST_STATUS) {
                isPermit = false;
            }

            const dataToSend = {
                isPermit: isPermit,
                personId: values.personId, 
                vehicleNum: values.vehicleNum, 
                otpCode: values.otpCode, 
                portalAction: modalOtpState.portalAction, 
                sendOtpCodeVia: values.sendOtpCodeVia,
            };

            const response = await sendOtpCodeApi(dataToSend);
            const data = response.data;
            
            if(!data?.status || data.status !== ResponseStatus.SUCCESS) {

                throw new Error(data?.message ? data.message : 'בעיה בשרת - לא החזיר תשובה');
            }

            success = true;

            notLoadingModal();
            despatchModalOtp({
                type: OtpModalActionKind.OTP_SENT_TO_MOBILE
            });

        } catch (error) {

            if (error.response) {
                
                const mailOrMobileError = 'פרטי הקשר אינם במערכת';
                message = translateErrorCode(error, { 
                    INVALID_OR_NOT_EXIST_MOBILE_IN_SYSTEM: mailOrMobileError, 
                    INVALID_OR_NOT_EXIST_EMAIL_IN_SYSTEM: mailOrMobileError,
                    REQUESTED_DATA_IS_NOT_FOUND: 'הבקשה אינה קיימת במערכת', 
                });
            
                loadProjectConfig();
            
            } else if (error.request) {
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

            setDangerAlertModal(message);
        }
    }
    
    function resetOtpForm() {
        resetForm();

        despatchModalOtp({
            type: OtpModalActionKind.OTP_RESET
        });

        notLoadingModal();
    }

    async function handleAddFileOtp(file: IFileDataBase64) {
        
        if(!file) {

            return;
        }

        loadingModal();
        let message = '';
        let success = false;

        try {

            const dataTosent = {
                personId: values.personId, 
                vehicleNum: values.vehicleNum, 
                otpCode: values.otpCode,
                file: null
            }

            const fileData = await convertFileToSendToServer(file);

            dataTosent.file = fileData
            const response = await addFileToRequestOtpApi(dataTosent);
            
            console.log(response)
            if(!response?.status || response.status !== ResponseStatus.SUCCESS) {

                throw new Error(response?.message ? response.message : 'בעיה בשרת - לא החזיר תשובה');
            }

            success = true;

            notLoadingModal();
            despatchModalOtp({
                type: OtpModalActionKind.OTP_SUCCESS_FILE
            });

            setAlertPage(AlertColors.SUCCESS, true,'קובץ עלה בהצלחה');

            closeOtpModal();
        } catch (error) {

            if (error.response) {

                message = translateErrorCode(error);

            } else if (error.request) {
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

            setDangerAlertPage(message);
        }
    }
    
    return (
        <HomePageContext.Provider value={{ 
            modalOtpState, closeOtpModal, openOtpModal, 
            handleCheckOtpCode, sendOtpCode, resetOtpForm, 
            componentInfoPage, componentInfoModal, handleAddFileOtp,
            Formik }}>
            <HomePage />
        </HomePageContext.Provider>
    );
}

export const useHomePageContext = () =>  useContext(HomePageContext);