import React from 'react';
import translateHtmlUncodeToChar from '../../../helpers/language/TranslateHtmlUncodeToChar';
import { useProjectConfigContext } from '../../../contexts/ProjectConfigContext';
import SelectWithSearch from './SelectWithSearch';
import { IVehicleGroups } from '../../../interface/config/IConfig.interface';

type Props = {
    name: string,
    handleBlur: (e: any) => void,
    handleChange: (option: IVehicleGroups) => void,
    errorMsg?: string | undefined,
    value: number,
    isRequired: boolean,
    disabled?: boolean
    //handleChange: (name: string, value: string) => void,
}

const SelectVehicleGroup = ({ name, handleBlur, handleChange, errorMsg, value }: Props) => {

    const { projectConfigState } = useProjectConfigContext();
    const vehicleGroupList = projectConfigState.vehicleGroups;
    
    let vehicleGroups = vehicleGroupList.map(vehicleGroup => {

        return { label: translateHtmlUncodeToChar(vehicleGroup.vehicleGroupName), value: vehicleGroup.id }
    });

    //vehicleGroups = [{ value: -1, label: 'אחר' }, ...vehicleGroups];

    //@ts-ignore
    const valueStr = vehicleGroups.findLast(option => option.value == value);

    return (
        <SelectWithSearch
            errorMsg={errorMsg}
            name={name}
            label={'סוג רכב'}
            value={valueStr}
            isRequired={true}
            selects={vehicleGroups}
            handleBlur={handleBlur}
            handleChange={handleChange} />
    )
}

export default SelectVehicleGroup;