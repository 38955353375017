import React from 'react'
import SelectInput from './SelectInput';
import translateHtmlUncodeToChar from '../../../helpers/language/TranslateHtmlUncodeToChar';
import { useProjectConfigContext } from '../../../contexts/ProjectConfigContext';
import SelectWithSearch from './SelectWithSearch';

const SelectCarMaker = ({name, handleBlur, handleChange, errorMsg, value}) => {
    const { projectConfigState } = useProjectConfigContext();
    let makers = [{ value: -1, label: 'אין אופציות' }];

    if (projectConfigState.makers !== undefined) {

        const makerList = projectConfigState.makers;
        makers = makerList.map(maker => {
            const makerJson = maker
            return { label: translateHtmlUncodeToChar(makerJson.name), value: makerJson.id }
        });

        makers = [{ value: -1, label: 'צריך לבחור' }, ...makers];

    }
    
    const valueStr = makers.findLast(option => option.value == value);

    return (
        <SelectWithSearch
            errorMsg={errorMsg}
            name={name}
            label={'דגם רכב'}
            value={valueStr}
            isRequired={true}
            selects={makers}
            handleBlur={handleBlur}
            handleChange={handleChange} />
    )
}

export default SelectCarMaker