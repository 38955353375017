import { usePermitRequestContext } from "../../../../contexts/PermitRequestContext";
import style from '../permitRequest.module.css';

function ConfirmationContent() {

    const { Formik, conclusionModalState } = usePermitRequestContext();
    const { values } = Formik;
    const { permitRequestId, asmachta, amountPayed } = conclusionModalState;

    const fullName = `${values.personDetails.privateName} ${values.personDetails.familyName}`;
   
    const p1 = 'תושב/ת יקר/ה';
    const p2 = 'בקשתך להנפקת תו חנייה התקבלה במשרדנו.';

    const p3 = 'אנו עושים את מירב המאמצים לאשרה בהקדם, ועם אישורה של הבקשה,'
        + ' תשלח אליך בהודעת דואר אלקטרוני,'
        + ' הודעה על כך(על כן חשוב שכתובת הדואר אלקטרוני שצוינה תהיה תקינה).';

    const p4 = <strong>חשוב להדגיש:</strong>;
    const p5 = 'עד לקבלת האישור, תו החנייה אינו בתוקף, ולא ניתן ליהנות מההטבות שהוא מקנה.';
    const p6 = <strong>בקשה שלא אושרה </strong>
    const p61 = ' עקב אי-עמידה בקריטריונים לזכאות להנפקת תו החנייה/או בגין בקשה שמולאה '
        + 'על ידי המבקש באופן לא תקין. ';
    const p62 = <strong>במידה ונגבו דמי הנפקת תו החנייה הם לא יוחזרו.</strong>;
    const p9 = 'נשמח להמשיך לעמוד לשירותכם.';
    const p7 = `מספר בקשה ${permitRequestId}.`;
    let p8 = '';

    if (amountPayed > 0) {
        p8 = (
            <>
            <br/>
            שולם {amountPayed} שקלים, מספר אסמכתא: {asmachta}.
            </>
        );
    }

    return (

        <div className={style.cofirmationContentDiv}>

        <div className={style.cofirmationContentName}>לכבוד {fullName}</div>
            <p>
                {p1}
                <br />
                {p2}
                <br />
                {p3}
                <br />
                {p4}
                <br />
                {p5}
                <br />
                {p6}
                {p61}
                {p62}
                <br />
                {p7}
                {p8}
                <br />
                <br />
                {p9}
            </p>

        </div>

    );
}

export default ConfirmationContent;