import React from 'react';
import { usePermitRequestContext } from '../../../../contexts/PermitRequestContext';
import InputGroup from '../../../../components/form/InputGroup';

import RadioBtnsContainer from '../../../../components/form/radio/RadioBtnsContainer';
import { useProjectConfigContext } from '../../../../contexts/ProjectConfigContext';
import SectionTitle from '../SectionTitle';
import FormSection from './FormSection';
import RadioOutlineBtn from '../../../../components/form/radio/RadioOutlineBtn';

function AdditionalAddressFormSection() {

    const { projectConfigState } = useProjectConfigContext();
    const { handleChange, Formik } = usePermitRequestContext();

    const { errors, values, touched, handleBlur, setFieldValue } = Formik;

    const hasAdditionalAddress = values.hasAdditionalAddress === 1 ? true : false ;
    const AdditionalAddrDetails = values.additionalContactDetails;
    const AdditionalAddrDetailsErrors = errors.additionalContactDetails ? errors.additionalContactDetails : {};
    const AdditionalAddrDetailsTouched = touched.additionalContactDetails ? touched.additionalContactDetails : {};

    if (!projectConfigState.hasAdditionalAddress) {
        return null;
    }

    return (
        <FormSection>
            <SectionTitle title='פרטי כתובת למשלוח' />
            <div className='row'>
                <div className='col-sm'>
                    <RadioBtnsContainer label={'האם קיימת כתובת שונה למשלוח תו חנייה?'}>
                        <RadioOutlineBtn
                            isChecked={hasAdditionalAddress}
                            label='כן'
                            value={1}
                            onClick={handleClick}
                            id="additionalAddressYes"
                            name='hasAdditionalAddress'
                        />
                        <RadioOutlineBtn
                            isChecked={!hasAdditionalAddress}
                            label='לא'
                            value={0}
                            onClick={handleClick}
                            id="additionalAddressNo"
                            name='hasAdditionalAddress'
                        />
                    </RadioBtnsContainer>
                </div>
                {hasAdditionalAddress ? (
                    <>
                        <div className='row'>
                            <div className='col-md-6 col-lg-3'>
                                <InputGroup
                                    name={'additionalContactDetails.mailCity'}
                                    label={'עיר למשלוח'}
                                    value={AdditionalAddrDetails.mailCity}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    isRequired={true}
                                    errorMsg={(AdditionalAddrDetailsErrors.mailCity && AdditionalAddrDetailsTouched.mailCity) ? AdditionalAddrDetailsErrors.mailCity : undefined} />
                            </div>
                            <div className='col-md-3 col-lg-3'>
                                <InputGroup
                                    name={'additionalContactDetails.mailStreet'}
                                    label={'רחוב'}
                                    value={AdditionalAddrDetails.mailStreet}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    isRequired={true}
                                    errorMsg={(AdditionalAddrDetailsErrors.mailStreet && AdditionalAddrDetailsTouched.mailStreet) ? AdditionalAddrDetailsErrors.mailStreet : undefined} />
                            </div>
                            <div className='col-md-6 col-lg-2'>
                                <InputGroup
                                    name={'additionalContactDetails.mailHouse'}
                                    label={'בית'}
                                    value={AdditionalAddrDetails.mailHouse}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    isRequired={true}
                                    errorMsg={(AdditionalAddrDetailsErrors.mailHouse && AdditionalAddrDetailsTouched.mailHouse) ? AdditionalAddrDetailsErrors.mailHouse : undefined} />
                            </div>

                        </div>
                        <div className='row'>
                            <div className='col-md-6 col-lg-2'>
                                <InputGroup
                                    name={'additionalContactDetails.mailAppartment'}
                                    label={'דירה'}
                                    value={AdditionalAddrDetails.mailAppartment}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    isRequired={true}
                                    errorMsg={(AdditionalAddrDetailsErrors.mailAppartment && AdditionalAddrDetailsTouched.mailAppartment) ? AdditionalAddrDetailsErrors.mailAppartment : undefined} />
                            </div>
                            <div className='col-md-6 col-lg-2'>
                                <InputGroup
                                    name={'additionalContactDetails.mailMikod'}
                                    label={'מיקוד'}
                                    value={AdditionalAddrDetails.mailMikod}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    isRequired={true}
                                    errorMsg={(AdditionalAddrDetailsErrors.mailMikod && AdditionalAddrDetailsTouched.mailMikod) ? AdditionalAddrDetailsErrors.mailMikod : undefined} />
                            </div>
                        </div>
                    </>) : ''
                }
            </div>
        </FormSection>
    );

    function handleClick(e) {

        setFieldValue('hasAdditionalAddress', Number(e.currentTarget.value));
    }
}

export default AdditionalAddressFormSection;