import React from 'react'
import { GrPowerReset } from 'react-icons/gr'
import { useHomePageContext } from '../../../../contexts/HomePageContext';
import Button from '../../../../components/buttons/Button';

const ResetPermitDetailsBtn = ({ disabled }) => {
    const { resetOtpForm } = useHomePageContext();

    return (
        <Button disabled={disabled} theme='secondary' onClick={resetOtpForm} endIcon={<GrPowerReset />} text='איפוס' />
    )
}

export default ResetPermitDetailsBtn