import React from 'react';
import Style from './Footer.module.css';
import logo from '../../assets/images/lolatech logo.png';

const Footer = () => {

    return(
        <footer className={Style.footerDiv}>
            <span className={Style.name}> לולהטק בע"מ © </span>
            <img className={Style.logo} src={logo} alt='image' />
        </footer>
    );
}

export default Footer;