import React from 'react';
import GeneralModal from '../../../../components/UI/modal/GeneralModal';
import { usePermitRequestContext } from '../../../../contexts/PermitRequestContext';
import ConfirmationContent from './ConfirmationContent';
import Button from '../../../../components/buttons/Button';
import { FaReceipt } from 'react-icons/fa6';

const SuccessModal = () => {

  const { closeAddReqConclusionModal, conclusionModalState } = usePermitRequestContext();
  const { isModalOpen, hasReceipt, receiptUrl } = conclusionModalState;

  if (!isModalOpen) return;

  return (
    <GeneralModal
      closingFunc={closeAddReqConclusionModal}
      modalSize={'M'}
      title={'התהליך הסתיים בהצלחה'}
      body={<ConfirmationContent />}
      footer={(
        <>
          {hasReceipt ? <Button text='הצג קבלה' endIcon={<FaReceipt />} onClick={openReceipt} /> : ''}
          <button className='btn btn-success' onClick={closeAddReqConclusionModal}>אישור</button>
        </>
      )}
    />
  );

  function openReceipt() {
    window.open(receiptUrl, '_blank');
  }
}

export default SuccessModal;