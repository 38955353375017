import React from 'react';

const FormSection = ({children}) => {
  return (
    <section>
        {children}
    </section>
  );
}

export default FormSection;