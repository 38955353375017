import React from 'react'
import Style from './button.module.css';

const Button = ({ children, onClick, theme, disabled, style, size, startIcon, endIcon, text, nowrap, type, title }) => {

    let spanStart = '';
    let spanEnd = '';
    let classes = Style.myBtn + ' ' + getClass(theme);
    classes += getSize(size);
    let classesIcon = Style.icon;

    if(nowrap) classes += ' ' + Style.btnNowrap;

    if(endIcon){

        classesIcon += ' ' + Style.startIcon;

        spanEnd = (
            <span className={classesIcon}>{endIcon}</span>
        );
    }

    if(startIcon){

        classesIcon += ' ' + Style.endIcon;

        spanStart = (
            <span className={classesIcon}>{startIcon}</span>
        );
    }

    return (
        <button
        style={style}
        disabled={disabled}
        type={type}
        title={title}
        onClick={e => onClick(e)}
        className={classes} >
            {spanStart}
            <span>{text}{children}</span>
            {spanEnd}
        </button>
    );

    function getClass(theme) {
        switch (theme) {
            case 'primary':
                return 'btn btn-primary';
            case 'secondary':
                return 'btn btn-secondary';
            case 'success':
                return 'btn btn-success';
            case 'danger':
                return 'btn btn-danger';
            case 'warning':
                return 'btn btn-warning';
            case 'info':
                return 'btn btn-info';
            case 'light':
                return 'btn btn-light';
            case 'dark':
                return 'btn btn-dark';
            case 'link':
                return 'btn btn-link';
            default:
                return 'btn btn-primary';
        }
    }

    function getSize(size){

        if(size === 'small') return ' btn-sm';

        if(size === 'big') return ' btn-lg';

        return '';
    }
}

Button.defaultProps = {
    disabled: false,
    theme: "primary",
    type: 'submit',
    style: {},
    onClick: () => { },
    size:'',
    title: '',
    startIcon: null,
    endIcon: null,
    text: '',
    nowrap: true
};

export default Button