import React from 'react';
import { useHomePageContext } from '../../../../../contexts/HomePageContext';
import { ActionPortalTypes } from '../../../../../types/ActionPortalTypes';
import AddFileForm from './AddFileForm';
import DisplayPermitIsCanceled from './DisplayPermitIsCanceled';
import DisplayPermitValidationDate from './DisplayPermitValidationDate';
import DisplayRequestStatus from './DisplayRequestStatus';

const ContainetFormsAfterOtpValidtion = () => {

    const { modalOtpState } = useHomePageContext();

    const portalAction = modalOtpState.portalAction;

    return (
        <>
            {portalAction === ActionPortalTypes.ADD_FILE_TO_REQUEST ? <AddFileForm /> : ''}
            {portalAction === ActionPortalTypes.CANCEL_PERMIT ? <DisplayPermitIsCanceled /> : ''}
            {portalAction === ActionPortalTypes.CHECK_PEMIT_VALIDITY ? <DisplayPermitValidationDate /> : ''}
            {portalAction === ActionPortalTypes.CHECK_REQUEST_STATUS ? <DisplayRequestStatus /> : ''}
        </>
    );
}

export default ContainetFormsAfterOtpValidtion;