import React from 'react';
import { usePermitRequestContext } from '../../../../../contexts/PermitRequestContext';
import RadioOutlineBtn from '../../../../../components/form/radio/RadioOutlineBtn';
import RadioBtnsContainer from '../../../../../components/form/radio/RadioBtnsContainer';
import InputGroup from '../../../../../components/form/InputGroup';

const HasBusinessFiles = () => {

    const { Formik, handleChangeFile } = usePermitRequestContext();
    const { errors, values, touched, handleBlur, setFieldValue } = Formik;

    const errorsExstraFiles = errors.extraFiles ? errors.extraFiles : {};
    const touchedExstraFiles = touched.extraFiles ? touched.extraFiles : {};

    return (
        <div className='row'>

            <div className='col-12'>

                <RadioBtnsContainer label={'עסק רשום בעיר?'}>
                    <RadioOutlineBtn
                        isChecked={values.extraFiles.hasBusiness === 1}
                        label='כן'
                        value={1}
                        onClick={handleClick}
                        id="hasBusinessYes"
                        name='extraFiles.hasBusiness'
                    />
                    <RadioOutlineBtn
                        isChecked={values.extraFiles.hasBusiness === 0}
                        label='לא'
                        value={0}
                        onClick={handleClick}
                        id="hasBusinessNo"
                        name='extraFiles.hasBusiness'
                    />
                </RadioBtnsContainer>

            </div>

            {values.extraFiles.hasBusiness === 1 ? (

                <div className='col-md-6 col-lg-4'>

                    <InputGroup
                        label={'סרוק ארנונה על שם העסק'}
                        name={'extraFiles.extraFile3'}
                        type={'file'}
                        isRequired={true}
                        handleBlur={handleBlur}
                        handleChange={(event) => {
                            handleChangeFile('extraFiles.extraFile3', 'extraFile3', event.currentTarget.files[0]);
                        }}
                        // @ts-ignore
                        errorMsg={(errorsExstraFiles.extraFile3 && touchedExstraFiles.extraFile3) ? errorsExstraFiles.extraFile3 : undefined}
                    />

                </div>

            ) : ''}
        </div>

    );


    function handleClick(e) {
        setFieldValue('extraFiles.hasBusiness', Number(e.currentTarget.value));
    }
}

/*
<div className='col-md-6 col-lg-4'>

                        <InputGroup
                            type={'file'}
                            name={'extraFiles.extraFile2'}
                            label={'אנא סרוק תעודת עוסקת מורשה'}
                            handleBlur={handleBlur}
                            handleChange={(event) => {
                                handleChangeFile('extraFiles.extraFile2', 'extraFile2', event.currentTarget.files[0]);
                            }}
                            isRequired={true}
                            errorMsg={(errors.extraFile2 && touched.extraFile2) ? errors.extraFile2 : undefined}
                        />
                        {errors.extraFile2}
                    </div>
*/

export default HasBusinessFiles;