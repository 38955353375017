import React from 'react';
import Button from './Button';
import { MdOutlineDoNotDisturbAlt } from "react-icons/md";

const CancelBtn = ({ onClick, label, theme, disabled, style, title }) => {
  return (
    <Button title={title} disabled={disabled} text={label} theme={theme} style={style} onClick={onClick} endIcon={<MdOutlineDoNotDisturbAlt />}/>
  )
}

CancelBtn.defaultProps = {
    disabled: false,
    theme: "danger",
    label: "ביטול",
    onClick: () => { },
    style: {},
    title: 'מבטל פעולה'
  };

export default CancelBtn;