import React from 'react'

type Props = {
    label: string,
    children: React.ReactNode
}

const RadioBtnsContainer = ({ children, label }: Props) => {
    return (
        <div className="form-check" style={{ display: 'flex', flexDirection: 'column' }}>
            <label htmlFor="hasEmail" className="form-check-label pb-2">{label}</label>
            <div style={{ display: 'flex', flexDirection: 'row', columnGap: '1rem' }}>
                {children}
            </div>
        </div>
    )
}

export default RadioBtnsContainer;