export const getBase64 = (file) => {

    return new Promise((res, rej) => {
        if(!file) {
            rej('No file provided to get base64');
        } else {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                reader.result && res(reader.result.toString().split(',')[1]);
            };
            reader.onerror = function (error) {
                rej(error);
            };
        }
    })
}
