import React from 'react';
import { Link } from 'react-router-dom';
import Page from '../components/UI/page/Page';

const NotFound = () => {

    const titleStyle = {
        fontSize:"3rem",
    }

    const ErrorStyle = {
        fontSize:"5rem",
    }

    const containerStyle = {
        paddingTop: "5rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    }

    return (
        <Page>
            <div style={containerStyle}>
                <div style={titleStyle}>דף לא קיים</div>
                <div style={ErrorStyle}>404</div>
            </div>
        </Page>
    );
}

export default NotFound;