import React from 'react';
import { BsSend } from 'react-icons/bs';
import CreditCardFormSection from './CreditCardFormSection';
import Takanon from '../Takanon';
import { usePermitRequestContext } from '../../../../contexts/PermitRequestContext';
import PersonDetailsFormSection from './PersonDetailsFormSection';
import CarDetailsFormSection from './CarDetailsFormSection';
import ContactDetailsFormSection from './ContactDetailsFormSection';
import AdditionalAddressFormSection from './AdditionalAddressFormSection';
import ExtraFileFormSection from './extraFiles/ExtraFileFormSection';


const PermitRequestForm = () => {

    const { handleSubmit } = usePermitRequestContext();

    return (
        <form style={{ display: 'flex', flexDirection: 'column', rowGap: '2rem' }}>
            <PersonDetailsFormSection />
            <CarDetailsFormSection />
            <ContactDetailsFormSection />
            <AdditionalAddressFormSection />
            <ExtraFileFormSection />
            <CreditCardFormSection />

            <div className='row'>
                <div className='col-12' style={{ display: 'flex', justifyContent: 'center' }}>
                    <hr className="bg-secondary border-2 border-top border-secondary" style={{ margin: '0px', width: '85%' }} />
                </div>
            </div>

            <Takanon />
            
            <div className='row'>
                <div className='col-12 col-lg-3'>
                    <button style={{width: '100%'}} type="button" className="btn btn-lg btn-success" onClick={(event) => onHandleSubmit(event)}>
                        שלח טופס <BsSend style={{ rotate: '270deg' }} />
                    </button>
                </div>
            </div>

        </form>
    );

    function onHandleSubmit(event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) {

        event.preventDefault();
        handleSubmit();
    }
}

export default PermitRequestForm;