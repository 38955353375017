import * as yup from 'yup';
import { onlyEnglishAndHebrewAndSpace, onlyNumbersRegExp } from '../../helpers/regExps';
import { ONLY_DIGIT_PERMITED_MESSAGE, ONLY_LETTERS_HEBREW_OR_ENGLISH_PERMITED_MESSAGE, REQUIRED_FIELD_MESSAGE } from '../errorMessages';

export const yupObjectAdditionalContactDetailsNotRequired = {
    mailCity: yup.string()
        .notRequired(),
    mailStreet: yup.string()
        .notRequired(),
    mailHouse: yup.string()
        .notRequired(),
    mailAppartment: yup.string()
        .notRequired(),
    mailMikod: yup.string()
        .notRequired(),
}

export const yupObjectAdditionalContactDetailsRequired = {
    mailCity: yup.string()
        .matches(onlyEnglishAndHebrewAndSpace, ONLY_LETTERS_HEBREW_OR_ENGLISH_PERMITED_MESSAGE)
        .required(REQUIRED_FIELD_MESSAGE),
    mailStreet: yup.string()
        .matches(onlyEnglishAndHebrewAndSpace, ONLY_LETTERS_HEBREW_OR_ENGLISH_PERMITED_MESSAGE)
        .required(REQUIRED_FIELD_MESSAGE),
    mailHouse: yup.string()
        .matches(onlyNumbersRegExp, ONLY_DIGIT_PERMITED_MESSAGE)
        .required(REQUIRED_FIELD_MESSAGE),
    mailAppartment: yup.string()
        .matches(onlyNumbersRegExp, ONLY_DIGIT_PERMITED_MESSAGE)
        .required(REQUIRED_FIELD_MESSAGE),
    mailMikod: yup.string()
        .matches(/^[0-9]+$/, ONLY_DIGIT_PERMITED_MESSAGE)
        .required(REQUIRED_FIELD_MESSAGE),
}