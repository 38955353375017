import { AiOutlineAlert } from "react-icons/ai";
import { BsInfoCircle } from "react-icons/bs";
import { GrStatusGood } from "react-icons/gr";
import { MdOutlineErrorOutline } from "react-icons/md";
import { HiBellAlert } from "react-icons/hi2";

export default function getDefaultIconByClass(type) {
    
    if(type === 'secondary') return <HiBellAlert size={27} />;
    if(type === 'success') return <GrStatusGood size={27} />;
    if(type === 'danger') return <MdOutlineErrorOutline size={27} />;
    if(type === 'warning') return <AiOutlineAlert size={27} />;
    if(type === 'info') return <BsInfoCircle size={27} />;
    if(type === 'light') return <HiBellAlert size={27} />;
    if(type === 'dark') return <HiBellAlert size={27} />;

    return null;
}