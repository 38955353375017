import React from 'react'
import { LuSend } from 'react-icons/lu'
import Button from '../../../../components/buttons/Button';
import { useHomePageContext } from '../../../../contexts/HomePageContext';

const SendOtpCodeToMobileBtn = ({ disabled }) => {
    const { sendOtpCode } = useHomePageContext();

    return (
        <Button disabled={disabled} onClick={sendOtpCode} endIcon={<LuSend />} text='שלח קוד' />
    )
}

export default SendOtpCodeToMobileBtn