import React, { createContext, FC, useContext, useEffect, useMemo, useState } from "react";
import { FormikProps, useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { permitRequestData } from "../data/initialize/permitRequest.data";
import { requestSchema } from "../validations/createRequest/permitRequestValidation";
import PermitRequestPage from "../pages/permitRequest/PermitRequestPage";
import useAddPermitRequestApi from "../api/useAddPermitRequestApi";
import { convertFileToSendToServer } from '../helpers/convertors';
import { useProjectConfigContext } from "./ProjectConfigContext";
import { MAX_FILE_SIZE, SUPPORTED_FORMATS } from "../validations/errorMessages";
import { useAddReqConclusionModal } from "../hooks/useAddReqConclusionModal";
import useCalculatePaymentAmount from "../hooks/usePaymentAmount";
import { IPermitRequest } from "../interface/permitRequest.interface";
import { IConfirmationModal } from "../interface/confirmationModal.interfase";
import { useComponentInfo } from "../hooks/useComponentInfo";
import { IComponentInfo } from "../interface/componentInfo.interface";
import { IStreets } from "../interface/config/IConfig.interface";
import { filterStreetsForPardesHannaKarkur } from "../helpers/streets/filterStreetsForPardesHannaKarkur";

type PermitRequestContextType = {
    openSendReqConfirmModal: boolean,
    handleSubmit: () => void,
    setOpenSendReqConfirmModal: (isOpen: boolean) => void,
    handleChange: (event: any) => void,
    conclusionModalState: IConfirmationModal,
    closeAddReqConclusionModal: () => void,
    openAddReqConclusionModal: () => void,
    submitPermitRequest: () => Promise<void>,
    Formik: FormikProps<IPermitRequest>,
    handleChangeFile: (inputName: any, fileName: any, file: any) => Promise<void>,
    componentInfo: IComponentInfo,
    filteredStreets: IStreets[],
    filteringStreets: () => void,
    handleExtra4MapChange:(name: string, value: string) => void,
    houseAndAppAndStreetDes: boolean,
}

export const PermitRequestContext = createContext<PermitRequestContextType | undefined>(undefined);

export const PermitRequestProvider: FC = ({ }) => {

    const { projectConfigState, authState } = useProjectConfigContext();
    const calculatePaymentAmount = useCalculatePaymentAmount();
    const tdsConfig = projectConfigState.tdsConfig;
    const colorAndType = tdsConfig.colorAndType;
    const vehicleGroups = projectConfigState.vehicleGroups;
    const hasAdditionalPersonIdFile = false;
    const navigate = useNavigate();
    const [openSendReqConfirmModal, setOpenSendReqConfirmModal] = useState(false);
    const [houseAndAppAndStreetDes, sethouseAndAppAndStreetDes] = useState(false);
    const [filteredStreets, setFilteredStreets] = useState([] as IStreets[]);
    const {componentInfo, setDangerAlert, loading, notLoading} = useComponentInfo();
    const addPermitRequestApi = useAddPermitRequestApi();
    const { closeAddReqConclusionModal: closeAddReqConclusionModalFunc,
        openAddReqConclusionModal, setDataAddReqConclusionModal, conclusionModalState } = useAddReqConclusionModal();

    const projectId = projectConfigState.projectId;

    const Formik = useFormik<IPermitRequest>({
        initialValues: permitRequestData,
        validateOnMount: true,
        validateOnChange: false,
        validationSchema: requestSchema,
        validateOnBlur: true,
        onSubmit:() => {},
    });

    const { errors, values, isValid, setFieldValue, setFieldTouched, validateForm } = Formik;

    useEffect(() => {

        handleCalPaymentAmount();

        setFieldValue('carDetails.hasColorAndType', colorAndType);
        setFieldValue('carDetails.vehicleGroup', findDefualtvehicleGroupVal());
        setFieldValue('personDetails.hasAdditionalPersonIdFile', hasAdditionalPersonIdFile);

        // loading init extra4Map values.
        if(projectConfigState.projectId === 18) {

            const extra4Map = {
                'isCityResident': '1',
                'needParkingInMunicipalPardesHannaKarkur': '0',
                'agriculturalSchoolFaculty': '0',
                'collectionOrWaterDepartmentEmployees': '0',
            };

            setFieldValue('extra4', {...extra4Map});
        }else if(projectConfigState.projectId === 1) {

            const extra4Map = {
                'isCityResident': '1'
            };

            setFieldValue('extra4', {...extra4Map});
        }

        initLoadStreets();

    }, [projectConfigState]);

    useEffect(() => {

        handleCalPaymentAmount();
    }, [values.permitType, values.contactDetails.streetId]);

    function findDefualtvehicleGroupVal() {

        const val = vehicleGroups.find(v => v.vehicleGroupName === '&#1508;&#1512;&#1496;&#1497;');

        return val ? val.id : -1;
    }

    function initLoadStreets() {
        let streets: IStreets[] = [{ id: -1, name: 'אין אופציות' }];

        if (projectConfigState.streets !== undefined) {
    
            const streetsList = projectConfigState.streets;
            streets = [{ id: -1, name: 'צריך לבחור' }, ...streetsList];
        }

        setFilteredStreets(streets);
    }

    const handleChange = useMemo(() => {
        return (event) => {
            setFieldValue(event.target.name, event.target.value);
        };
    }, []);

    function filteringStreets() {

    }

    async function handleSubmit() {

        const formErrors = await validateForm(values);

        if (!isValid) {
            setAllKeysInObjectAsToutch('', formErrors);
            onFocusAlert();
            setDangerAlert('בעיה עם אחד או יותר מהשדות, אנא תקן ונסה לשלוח שוב.');

            console.log(`errors: ${JSON.stringify(errors)}`);
            console.log(`values: ${JSON.stringify(values)}`);
            return;
        }

        setOpenSendReqConfirmModal(true);
    }

    function setAllKeysInObjectAsToutch(prevParants, obj) {

        for (const key in obj) {
            if (!obj.hasOwnProperty(key))
                continue;

            const superKey = prevParants ? `${prevParants}.${key}` : key;
            const value = obj[key];

            if (typeof value === 'object' && !Array.isArray(value) && value !== null) {

                setAllKeysInObjectAsToutch(superKey, value);
            } else {
                setFieldTouched(superKey, true, false);
            }

        }
    }

    async function handleChangeFile(inputName, fileName, file) {

        const result = {
            content: '',
            name: '',
            size: 0,
            contentType: '-1'
        }

        if (!file) {

            setFieldValue(inputName, result, true);
            return;
        }

        if (file?.size > MAX_FILE_SIZE) {

            //result.contentType = MAX_FILE_SIZE + 100;
            setFieldValue(inputName, result, true);
            return;
        }

        if (!SUPPORTED_FORMATS.includes(file?.type)) {

            result.contentType = file.type;
            setFieldValue(inputName, result, true);
            return;
        }


        try {

            const result = await convertFileToSendToServer(file, fileName);
            setFieldValue(inputName, result, true);
        } catch (error) {

            setFieldValue(inputName, {}, true);
        }
    }

    function closeAddReqConclusionModal() {

        closeAddReqConclusionModalFunc();
        navigate(`/home/${projectId}`);
    }

    async function submitPermitRequest() {

        loading();

        const res = await addPermitRequestApi(values);

        if (res.success) {

            setDataAddReqConclusionModal(res.asmachta, res.permitRequestId, res.amountPayed, res.hasReceipt, res.receiptUrl);
        } else {

            onFocusAlert();
            setDangerAlert(res.apiError.displayMessage);
        }

        notLoading();
    } 

    

    function onFocusAlert() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    async function handleCalPaymentAmount() {

        const loggin = authState.loggin;

        if (!loggin) {
            return;
        }

        const paymentAmount = await calculatePaymentAmount(values.permitType, values.contactDetails.streetId);
        setFieldValue('paymentAmount', paymentAmount);
    }

    function handleExtra4MapChange(name: string, value: string) {

        const newExtra4 = {...values.extra4, [name]: value};
        
        if(projectId === 18) {

            let isCityResident = newExtra4.isCityResident;
            let needParkingInMunicipalPardesHannaKarkur = newExtra4.needParkingInMunicipalPardesHannaKarkur;
            let agriculturalSchoolFaculty = newExtra4.agriculturalSchoolFaculty;
            let collectionOrWaterDepartmentEmployees = newExtra4.collectionOrWaterDepartmentEmployees;
            let streetId = '-1';

            if(isCityResident === '0') {
   
                if(needParkingInMunicipalPardesHannaKarkur !== '1' && agriculturalSchoolFaculty !== '1' && collectionOrWaterDepartmentEmployees !== '1') {
    
                    streetId = '9002';
                    newExtra4['needParkingInMunicipalPardesHannaKarkur'] = '1';
                } else if (needParkingInMunicipalPardesHannaKarkur === '1') {
                    streetId = '9002';
                } else if (agriculturalSchoolFaculty === '1') {
                    streetId = '9007';
                } else if (collectionOrWaterDepartmentEmployees === '1') {
                    streetId = '9009';
                }
            } 

            if(name === 'isCityResident' && value === '0') {
                if(needParkingInMunicipalPardesHannaKarkur === '1') {
                    streetId = '9002';
                }
            }
            
            if(name === 'agriculturalSchoolFaculty' && value === '1') {
            
                newExtra4['needParkingInMunicipalPardesHannaKarkur'] = '0';
                newExtra4['collectionOrWaterDepartmentEmployees'] = '0';
                
                if(isCityResident === '1') {
                    streetId = '9006';
                } else {
                    streetId = '9007';
                }
            } 
            
            if(name === 'collectionOrWaterDepartmentEmployees' && value === '1') {
            
                newExtra4['needParkingInMunicipalPardesHannaKarkur'] = '0';
                newExtra4['agriculturalSchoolFaculty'] = '0';

                if(isCityResident === '1') {
                    streetId = '9008';
                } else {
                    streetId = '9009';
                }
            }

            if(name === 'needParkingInMunicipalPardesHannaKarkur' && value === '1') {
            
                newExtra4['collectionOrWaterDepartmentEmployees'] = '0';
                newExtra4['agriculturalSchoolFaculty'] = '0';

                if(isCityResident === '0') {
                    streetId = '9002';
                } 
            }

            if(newExtra4.collectionOrWaterDepartmentEmployees === '1' 
                || newExtra4.agriculturalSchoolFaculty === '1' || newExtra4.isCityResident === '0') {
                
                sethouseAndAppAndStreetDes(true);
                setFieldValue('contactDetails.house', '1');
                setFieldValue('contactDetails.appartment', '1');
                setFieldValue('contactDetails.streetId', streetId);
            } else {

                sethouseAndAppAndStreetDes(false);
                setFieldValue('contactDetails.house', '');
                setFieldValue('contactDetails.appartment', '');
                setFieldValue('contactDetails.streetId', '-1');
            }
            
            const newFilteredStreet = filterStreetsForPardesHannaKarkur(projectConfigState.streets, newExtra4);
            setFilteredStreets(newFilteredStreet);
        }

        setFieldValue('extra4', {...newExtra4});
    }

    return (
        <PermitRequestContext.Provider value={
            {
                openSendReqConfirmModal, setOpenSendReqConfirmModal,
                Formik, handleChange,
                conclusionModalState, closeAddReqConclusionModal, openAddReqConclusionModal, submitPermitRequest, handleChangeFile,
                componentInfo,
                handleSubmit,
                filteringStreets,
                filteredStreets,
                handleExtra4MapChange,
                houseAndAppAndStreetDes,
            }
        }>
            <PermitRequestPage />
        </PermitRequestContext.Provider>
    );

}

export const usePermitRequestContext = () => useContext(PermitRequestContext);