import React from 'react'
import { LuSend } from 'react-icons/lu'
import { useHomePageContext } from '../../../../contexts/HomePageContext';
import Button from '../../../../components/buttons/Button';

const CheckOtpCodeFromMobileBtn = ({disabled}) => {
    const { handleCheckOtpCode } = useHomePageContext();

  return (
    <Button disabled={disabled} theme='success' onClick={handleCheckOtpCode} endIcon={<LuSend />} text='אמת קוד' />
  )
}

export default CheckOtpCodeFromMobileBtn