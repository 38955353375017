import React from 'react';
import RedStar from '../RedStar';

const SelectInput = ({ name, label, errorMsg, selects, isRequired, handleChange, handleBlur }) => {

    let classes = 'form-select ';
    const options = selects.map((option, index) => {

        return <option key={index} value={option.value}>{option.name}</option>;
    });

    const hasError = errorMsg ? true : false;

    if(hasError){
        classes += ' is-invalid';
    }
    return (
        <>

            <label className="form-label" htmlFor={`${name}id`}>{label}</label>
            {isRequired && <RedStar />}

            <select
                className={classes}
                id={`${name}id`}
                name={name}
                onChange={handleChange}
                required={isRequired}
                onBlur={handleBlur}
                aria-label="Default select example">
                {options}
            </select>
            {hasError ? <div id='asas' className="invalid-feedback"> {errorMsg} </div> : ''}
        </>
    )
}

export default SelectInput