import React from 'react';
import RadioBtn from '../../components/form/radio/RadioBtn';
import { useProjectConfigContext } from '../../contexts/ProjectConfigContext';
import { IExtra4Map } from '../../interface/permitRequest.interface';
import { CheckBoxColors } from '../../types/colors/checkBoxColors';

type Props = {
  extra4Map: IExtra4Map,
  handleChange: (needParkingInMunicipalPHK: string, isCityResident: string) => void,
}

const CheckBoxesForPardesHannaKarkur = ({ extra4Map, handleChange }: Props) => {

  const { projectConfigState } = useProjectConfigContext();

  const projectId = projectConfigState.projectId;

  if (projectId !== 18 && projectId !== 1) {
    return null;
  }

  return (
    <div className='row align-items-end'>


      {extra4Map.isCityResident ? (
        <div className="col-12 mt-1 mb-2">
          <RadioBtn
            isChecked={extra4Map.isCityResident === '1'}
            label='תושב.ת המועצה / בעלי עסקים'
            value={extra4Map.isCityResident}
            onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
              handleOnChange('isCityResident', e);
            }}
            id="isCityResidentId"
            name='isCityResident'
            color={CheckBoxColors.SUCCESS}
          />
        </div>
      ) : ''}

      {extra4Map.needParkingInMunicipalPardesHannaKarkur ? (
        <div className="col-12">
          <RadioBtn
            isChecked={extra4Map.needParkingInMunicipalPardesHannaKarkur === '1'}
            label='עובד.ת מועצה או ביה"ס אלונים'
            value={extra4Map.needParkingInMunicipalPardesHannaKarkur}
            onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
              handleOnChange('needParkingInMunicipalPardesHannaKarkur', e);
            }}
            id="needParkingInMunicipalPardesHannaKarkurId"
            name='needParkingInMunicipalPardesHannaKarkur'
            color={CheckBoxColors.SUCCESS}
          />
        </div>
      ) : ''}

      {extra4Map.agriculturalSchoolFaculty ? (
        <div className="col-12">
          <RadioBtn
            isChecked={extra4Map.agriculturalSchoolFaculty === '1'}
            label='סגל ביה"ס חקלאי'
            value={extra4Map.agriculturalSchoolFaculty}
            onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
              handleOnChange('agriculturalSchoolFaculty', e);
            }}
            id="agriculturalSchoolFacultyId"
            name='agriculturalSchoolFaculty'
            color={CheckBoxColors.SUCCESS}
          />
        </div>
      ) : ''}

      {extra4Map.collectionOrWaterDepartmentEmployees ? (
        <div className="col-12">
          <RadioBtn
            isChecked={extra4Map.collectionOrWaterDepartmentEmployees === '1'}
            label='עובדי מחלקת גבייה/מים'
            value={extra4Map.collectionOrWaterDepartmentEmployees}
            onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
              handleOnChange('collectionOrWaterDepartmentEmployees', e);
            }}
            id="collectionOrWaterDepartmentEmployeesId"
            name='collectionOrWaterDepartmentEmployees'
            color={CheckBoxColors.SUCCESS}
          />
        </div>
      ) : ''}

    </div>
  );

  function handleOnChange(name: string, e: React.MouseEvent<HTMLInputElement, MouseEvent>) {
    handleChange(name, e.currentTarget.value === '1' ? '0' : '1');
  }
}

export default CheckBoxesForPardesHannaKarkur;