import React from 'react';
import GeneralModal from '../../../../components/UI/modal/GeneralModal';
import OtpForm from './OtpForm';
import { useHomePageContext } from '../../../../contexts/HomePageContext';
import style from '../../homePage.module.css';
import DangerAlert from '../../../../components/Alerts/DangerAlert';
import ContainetFormsAfterOtpValidtion from './formsAfterOtpValidtion/ContainetFormsAfterOtpValidtion';
import { OtpStatus } from '../../../../types/OtpStatus';


const OtpModal = () => {

    const { closeOtpModal, componentInfoModal, modalOtpState } = useHomePageContext();

    const otpStatus = modalOtpState.otpStatus;

    if (!modalOtpState.isOtpModalOpen)
        return null;

    return (
        <GeneralModal
            closingFunc={() => closeOtpModal()}
            title={modalOtpState.modalTitle}
            body={(
                <div className={style.otpBody}>
                    <DangerAlert isShow={componentInfoModal.hasAlert} message={componentInfoModal.message} />
                    {otpStatus !== OtpStatus.OTP_SUCCESS ? <OtpForm /> : ''}
                    {otpStatus === OtpStatus.OTP_SUCCESS ? <ContainetFormsAfterOtpValidtion /> : ''}
                </div>
            )}
            hasFooter={false}
        />
    );
}

export default OtpModal;