import React, { useEffect } from 'react';
import style from './homePage.module.css';
import ActionBoxContainer from './components/ActionBoxContainer';
import { useProjectConfigContext } from '../../contexts/ProjectConfigContext';
import { useParams } from 'react-router-dom';
import OtpModal from './components/otpModal/OtpModal';
import { useHomePageContext } from '../../contexts/HomePageContext';
import LoadingModal from '../../components/loadingModal/LoadingModal.jsx';
import Alert from '../../components/Alerts/Alert';

const HomePage = () => {
    
    const params = useParams();
    const { setProjectId, isProjectConfigLoading } = useProjectConfigContext();
    const { componentInfoPage } = useHomePageContext();
    
    const projectId = Number(params.projectId);

    useEffect(() => {
        setProjectId(projectId);
    }, [projectId]);

    return (
        <>
            { isProjectConfigLoading ? <LoadingModal /> : '' }
            <OtpModal /> 
            <Alert type={componentInfoPage.alertColor} isShow={componentInfoPage.hasAlert} message={componentInfoPage.message} />
            <div className={style.homePage}>
                <ActionBoxContainer />
            </div>
        </>
    );
}

export default HomePage;