import React from 'react';
import ReactDOM from 'react-dom';
import Style from './loadingModal.module.css';



const Backdrop = () => {
  return <div className={Style.backdrop}></div>
}

const ModalOverlay = () => {

  return (
    <div className={Style.modal}>
      <div className={Style.content}>
      <div className="d-flex justify-content-center">
            <div className="spinner-border text-success" style={{width:'9rem', height:'9rem', fontSize: '4rem'}} role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
      </div>
    </div>
  );
}


const LoadingModal = () => {

  const portalElement = document.getElementById('overlays');

  return (
    <React.Fragment>
      {ReactDOM.createPortal(<Backdrop />, portalElement)}
      {ReactDOM.createPortal(<ModalOverlay />, portalElement)}
    </React.Fragment>
  )
}

export default LoadingModal;