import { IAuth } from "../interface/config/IConfig.interface";

export enum AuthActionKind {
    SET_TOKEN = 'SET_TOKEN'
}

// An interface for our actions
type AuthAction = {
    type: AuthActionKind.SET_TOKEN;
    token: string;
    loggin: boolean,
};

export function authReducer(state: IAuth, action: AuthAction) {

    switch (action.type) {
        case AuthActionKind.SET_TOKEN:

            console.log(`action.token: ${action.token}, action.loggin: ${action.loggin}`);
            return {
                ...state, 
                token: action.token,
                loggin: action.loggin,
            };
        default:
            return state;
    }
}
