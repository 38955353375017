import React from 'react'
import ReactDOM from 'react-dom';
import style from './modal.module.css';

const Backdrop = () => {
  return <div className={style.backdrop}></div>
}

const ModalOverlay = ({ children, modalSize }) => {

  let classes = style.modal;

  switch (modalSize) {
    case 'XS':
      classes += ' ' + style.modalSizeXS;
      break;
    case 'S':
      classes += ' ' + style.modalSizeS;
      break;
    case 'M':
      classes += ' ' + style.modalSizeM;
      break;
    case 'L':
      classes += ' ' + style.modalSizeL;
      break;
    case 'XL':
      classes += ' ' + style.modalSizeXL;
      break;
    default:
      classes += ' ' + style.modalSizeM;
      break;
  }

  return (
    <div className={classes}>
      {children}
    </div>
  );
}

const portalElement = document.getElementById('overlays');

const Modal = ({ children, modalSize }) => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(<Backdrop />, portalElement)}
      {ReactDOM.createPortal(<ModalOverlay modalSize={modalSize}>{children}</ModalOverlay>, portalElement)}
    </React.Fragment>
  );
}

Modal.defaultProps = {
  modalSize: 'M'
};

export default Modal;