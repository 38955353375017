import React from 'react'
import RedStar from './RedStar';


type InputGroupProps = {
    label: string;
    value: string;
    name: string;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    errorMsg?: string | undefined;
    isRequired?: boolean;
    type?: 'text' | 'file' | 'number' | 'email';
    disabled?: boolean;
}

const InputGroup = ({ label, value, name, handleChange, handleBlur, errorMsg, isRequired, type, disabled }: InputGroupProps) => {

    let classes = 'form-control';
    const hasError = errorMsg ? true : false;

    if(hasError){
        classes += ' is-invalid';
    }

    return (
        <>
            <label className="form-label">
                <div>
                {label}
                {isRequired ? <RedStar /> : ''}
                </div>
            </label>
           
            { 
            type === 'file' ? <input type={type} disabled={disabled} onBlur={handleBlur} className={classes} onChange={handleChange} name={name} /> :
                <input type={type} disabled={disabled} onBlur={handleBlur} className={classes} onChange={ (event) => handleChange(event)} name={name} value={value} />
            }
            {hasError ? <div id='asas' className="invalid-feedback"> {errorMsg} </div> : ''}
        </>
    )
}

InputGroup.defaultProps = {

    type: 'text',
    value: '',
    isRequired: false,
    disabled: false,
    errorMsg: '',
    handleChange: () => { },
    updateIsTouch: () => { }
}

export default InputGroup;