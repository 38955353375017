import * as yup from 'yup';
import { MOBILE_FORMAT_MESSAGE, ONLY_DIGIT_PERMITED_MESSAGE, REQUIRED_FIELD_MESSAGE } from "../errorMessages";
import { onlyNumbersRegExp, phoneRegExp } from '../../helpers/regExps';
import {isNumeric} from '../../helpers/validates/isNumeric';

export const yupObjectContactDetails = {
    streetId: yup.string()
        .notOneOf([-1, '-1'], 'אנא בחר רחוב')
        .required(REQUIRED_FIELD_MESSAGE),
    house: yup.string()
        .test(
            "house",
            'מספר בית חייב להיות מספר שגדול מאפס',
            (value) => {

                if (!value || !isNumeric(value)) {
                    return false;
                }

                let num = Number(value);

                return num > 0;
            }
        )
        .required(REQUIRED_FIELD_MESSAGE)
        .matches(onlyNumbersRegExp, ONLY_DIGIT_PERMITED_MESSAGE),
    appartment: yup.string()

        .test(
            "appartment",
            'מספר דירה חייב להיות מספר שגדול מאפס',
            (value) => {

                if (!value) return true;

                if (!isNumeric(value)) {
                    return false;
                }

                let num = Number(value);

                return num > 0;
            }
        )
        .matches(onlyNumbersRegExp, ONLY_DIGIT_PERMITED_MESSAGE),
    email: yup.string()
        .email('כתובת אימייל לא חוקית')
        .required(REQUIRED_FIELD_MESSAGE),
    mobile: yup.string()
        .required(REQUIRED_FIELD_MESSAGE)
        .matches(phoneRegExp, MOBILE_FORMAT_MESSAGE),
    phone: yup.string()
        .matches(phoneRegExp, MOBILE_FORMAT_MESSAGE),
}