import React from 'react'
import Alert from '../../../../../components/Alerts/Alert'

const CanceledPermitWarning = () => {
  return (
    <div className='alert alert-warning'>
        ידוע לך כי ביצוע הפעולה יגרע את תו הדייר שפרטיו תזין למערכת.
    </div>
  )
}

export default CanceledPermitWarning