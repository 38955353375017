import React from 'react';
import { useNavigate } from "react-router-dom";
import style from './header.module.css';
import { useProjectConfigContext } from '../../contexts/ProjectConfigContext';

const Header = () => {

  const navigate = useNavigate();
  const { projectConfigState } = useProjectConfigContext();
  const cityName = projectConfigState.cityHebName;
  const projectId = projectConfigState.projectId;

  return (
    <header className={style.header}>
        <div className={style.cityLogoDiv}>
          <img onClick={() => navigateTo(`home/${projectId}`)} className={style.cityLogo} src={projectConfigState.cityLogoPath} />
        </div>
        <div className={style.headerTitleDiv}>
          <span className={style.headerTitle} onClick={() => navigateTo(`home/${projectId}`)}>
            פורטל תווים <span className={style.lolaPortal}> {cityName} </span>
          </span>
        </div>
        <div className={style.emptyDiv}></div>
    </header>
  );

  function navigateTo(path) {
    navigate(path);
  }
}

export default Header;