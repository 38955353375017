import { AlertColors } from "../../types/colors/alertColors";

export default function getFullBootstarpClass(type: AlertColors) {
    let classes = "alert alert-primary";

    if(type === AlertColors.SECONDARY) classes = "alert alert-secondary";
    if(type === AlertColors.SUCCESS) classes = "alert alert-success";
    if(type === AlertColors.DANGER) classes = "alert alert-danger";
    if(type === AlertColors.WARNING) classes = "alert alert-warning";
    if(type === AlertColors.INFO) classes = "alert alert-info";
    if(type === AlertColors.LIGHT) classes = "alert alert-light";
    if(type === AlertColors.DARK) classes = "alert alert-dark";

    return classes;
}