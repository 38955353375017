import React from 'react';
import Style from './Page.module.css';

const Page = (props) => {

    let classes = 'container ' + Style.page;

    return (
    <div className={classes}>
        {props.children}
    </div>);
}

export default Page;