import React from 'react';
import { CheckBoxColors } from '../../../types/colors/checkBoxColors';

type Props = {
    value: number | string, 
    id: string, 
    name: string, 
    onClick: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) =>  void,  
    onBlur?: () =>  void, 
    label: string, 
    isChecked: boolean,
    color?: CheckBoxColors
}

const RadioOutlineBtn = ({ value, id, name, onClick, onBlur, label, isChecked, color }: Props) => {

    const bootstrapClassOutline = color ? `btn-outline-${color}` : "btn-outline-success";

    return (
        <>
            <input
                checked={isChecked}
                value={value}
                onBlur={onBlur}
                id={id}
                name={name}
                className="btn-check"
                type='radio'
                autoComplete="off"
                onClick={(e) => onClick(e)}
            />
            <label 
                className={`btn ${bootstrapClassOutline}`} 
                htmlFor={id}
                >
                {label}
            </label>
        </>
    )
}

export default RadioOutlineBtn;