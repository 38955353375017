export default function validatorPersonId(personId){

    if(personId === null  || personId === undefined  || personId.trim() === '') 
        return false;
        
    if (personId.length !== 9 || isNaN(personId)) return false;
    
    return Array.from(personId, Number).reduce((counter, digit, i) => {
        const step = digit * ((i % 2) + 1);
        return counter + (step > 9 ? step - 9 : step);
    }) % 10 === 0;
}

