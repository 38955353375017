import * as yup from 'yup';
import { MAX_FILE_SIZE, MAX_FILE_SIZE_MESSAGE, ONLY_DIGIT_PERMITED_MESSAGE, ONLY_LETTERS_ENGLISH_PERMITED_MESSAGE, ONLY_LETTERS_HEBREW_OR_ENGLISH_PERMITED_MESSAGE, REQUIRED_FIELD_MESSAGE, SUPPORTED_FORMATS, TYPE_FILE_MESSAGE } from "../errorMessages";
import { onlyEnglishAndHebrewAndSpace, onlyEnglishAndSpace, onlyNumbersRegExp } from '../../helpers/regExps';

export const yupObjectCreditCardNotRequired = {
    cardNumber: yup.string()
        .notRequired(),
    cardExpireDateYY: yup.string()
        .notRequired(),
    cardExpireDateMM: yup.string()
        .notRequired(),
    cardAddedNumber: yup.string()
        .notRequired(),
    cardHolderId: yup.string()
        .notRequired()
}

export const yupObjectCreditCardRequired = {
    cardNumber: yup.string()
        .matches(onlyNumbersRegExp, ONLY_DIGIT_PERMITED_MESSAGE)
        .required(REQUIRED_FIELD_MESSAGE),
    cardExpireDateYY: yup.string()
        .notOneOf([-1, '-1'], 'אנא בחר שנה')
        .required(REQUIRED_FIELD_MESSAGE),
    cardExpireDateMM: yup.string()
        .notOneOf([-1, '-1'], 'אנא בחר חודש')
        .required(REQUIRED_FIELD_MESSAGE),
    cardAddedNumber: yup.string()
        .matches(onlyNumbersRegExp, ONLY_DIGIT_PERMITED_MESSAGE)
        .required(REQUIRED_FIELD_MESSAGE),
    cardHolderId: yup.string()
        .matches(onlyNumbersRegExp, ONLY_DIGIT_PERMITED_MESSAGE)
        .required(REQUIRED_FIELD_MESSAGE),
    cardHolderName: yup.string()
        .matches(onlyEnglishAndSpace, ONLY_LETTERS_ENGLISH_PERMITED_MESSAGE)
        .required(REQUIRED_FIELD_MESSAGE),
}