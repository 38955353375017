import React from 'react';
import Style from './alers.module.css';
import getFullBootstarpClass from '../../helpers/alerts/getFullBootstarpAlertClass';
import getDefaultIconByClass from '../../helpers/alerts/getDefaultIconByClass';
import { IoMdClose } from 'react-icons/io';
import { AlertColors } from '../../types/colors/alertColors';

const CloseBtn = ({ onClick }) => {

    return (
        <span className={Style.btnCloseAlet} onClick={onClick}>
            <IoMdClose className={Style.btnCloseAletIcon} />
        </span>
    );
}

type Props = {
    type: AlertColors, 
    message: string | React.ReactNode, 
    iconStart?: string, 
    isShow: boolean, 
    onClose?: () => void, 
    subject?: string
}

const Alert = ({ type, message, iconStart, isShow, onClose, subject }: Props) => {

    if (!isShow) {
        return null;
    }

    let classes = '';
    let iconStartSpan: string | React.ReactNode = '';

    classes = getFullBootstarpClass(type);
    iconStartSpan = getDefaultIconByClass(type);

    if (iconStart != null) iconStartSpan = <span>{iconStart}</span>;

    return (
        <>
            {subject ? (
                <div className={classes} role="alert">
                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '1.2rem' }}>
                        <div style={{
                            display: 'flex', justifyContent: 'space-between',
                            alignItems: 'center', gap: '0.5rem',
                        }}>
                            {iconStartSpan}
                            <span style={{ display: 'inline', fontSize: '1.2rem', fontWeight: '500' }}>{subject}</span>
                        </div>
                        <div>
                            {onClose && <CloseBtn onClick={onClose} />}
                        </div>
                    </div>
                    <div style={{ fontSize: '1.1rem' }}>
                        {message ? message : ''}
                    </div>
                </div>
            ) : ''}
            {!subject ? (
                <div className={classes} role="alert">
                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '1.2rem' }} >
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0.5rem' }}>
                            {iconStartSpan}
                            <span style={{ display: 'inline', fontSize: '1.1rem' }}>{message}</span>
                        </div>
                        {onClose && <CloseBtn onClick={onClose} />}
                    </div>
                </div>
            ) : ''}
        </>
    );
}

export default Alert;