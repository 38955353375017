import React from 'react';
import InputGroup from '../../../../components/form/InputGroup';
import PermitRequestType from './PermitRequestType';
import { usePermitRequestContext } from '../../../../contexts/PermitRequestContext';
import SectionTitle from '../SectionTitle';
import FormSection from './FormSection';

function PersonDetailsFormSection() {

    const { handleChange,  handleChangeFile, Formik } = usePermitRequestContext();
    const {errors, values, touched, handleBlur} = Formik;
    const personDetailsValues = values.personDetails;
    const personDetailsErrors = errors.personDetails ? errors.personDetails : {};
    const personDetailsTouched = touched.personDetails ? touched.personDetails : {};

    const personFileLabel = personDetailsValues.hasAdditionalPersonIdFile ? 'צילום תעודת זהות' : 'צילום תעודת זהות וספח(בקובץ אחד)';

    return (
        <FormSection>
            <SectionTitle title='פרטי המבקש' />
            <div className='row'>
                <div className='col-6 col-lg-4 col-lg-3'>
                    <InputGroup
                        type={'text'}
                        name={'personDetails.privateName'}
                        label={'שם פרטי'}
                        value={personDetailsValues.privateName}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        isRequired={true}
                        errorMsg={(personDetailsErrors.privateName && personDetailsTouched.privateName) ? personDetailsErrors.privateName : undefined}
                    />

                </div>
                <div className='col-6 col-lg-4 col-lg-3'>
                    <InputGroup
                        type={'text'}
                        name={'personDetails.familyName'}
                        label={'שם משפחה'}
                        value={personDetailsValues.familyName}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorMsg={(personDetailsErrors.familyName && personDetailsTouched.familyName) ? personDetailsErrors.familyName : undefined}
                        isRequired={true}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6 col-lg-4'>
                    <InputGroup
                        name={'personDetails.id'}
                        label={'תעודת זהות'}
                        type='number'
                        value={personDetailsValues.id}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        isRequired={true}
                        errorMsg={(personDetailsErrors.id && personDetailsTouched.id) ? personDetailsErrors.id : undefined}
                    />
                </div>
                <div className='col-md-6 col-lg-4'>
                    <InputGroup
                        type={'file'}
                        name={'personDetails.scanId'}
                        label={personFileLabel}
                        handleBlur={handleBlur}
                        handleChange={(event) => {
                            handleChangeFile('personDetails.scanId', 'scanId', event.currentTarget.files[0]);
                        }}
                        isRequired={true}
                        errorMsg={(personDetailsErrors.scanId && personDetailsTouched.scanId) ? personDetailsErrors.scanId : undefined}
                    />
                </div>
                {personDetailsValues.hasAdditionalPersonIdFile ? (
                    <div className='col-md-6 col-lg-4'>
                        <InputGroup
                            type={'file'}
                            name={'personDetails.additionalPersonIdFile'}
                            label='צילום ספח תעודת זהות'
                            handleBlur={handleBlur}
                            handleChange={(event) => {
                                handleChangeFile('personDetails.additionalPersonIdFile', 'additionalPersonIdFile', event.currentTarget.files[0]);
                            }}
                            isRequired={true}
                            errorMsg={(personDetailsErrors.additionalPersonIdFile && personDetailsTouched.additionalPersonIdFile) ? personDetailsErrors.additionalPersonIdFile : undefined}
                        />
                    </div>
                ) : ''
                }
            </div>

            <PermitRequestType />
        </FormSection>

    );
}

PersonDetailsFormSection.defaultProps = {
    isChecked: false
}

export default PersonDetailsFormSection;