
const decodeUncodeMap = {
    '1488;': 'א',
    '1489;': 'ב',
    '1490;': 'ג',
    '1491;': 'ד',
    '1492;': 'ה', 
    '1493;': 'ו', 
    '1494;': 'ז',
    '1495;': 'ח',
    '1496;': 'ט',
    '1497;': 'י',
    '1498;': 'ך',
    '1499;': 'כ', 
    '1500;': 'ל', 
    '1501;': 'ם',
    '1502;': 'מ',
    '1503;': 'ן', 
    '1504;': 'נ',
    '1505;': 'ס',
    '1506;': 'ע', 
    '1507;': 'ף',
    '1508;': 'פ',
    '1509;': 'ץ', 
    '1510;': 'צ',
    '1511;': 'ק',
    '1512;': 'ר',
    '1513;': 'ש',
    '1514;': 'ת'
}

const translateHtmlUncodeToChar = (sentence: string) => {
    let translateSentence = '';
    let copyChar = true
    let unicodeWord = '';

    if(sentence === undefined || sentence === null)
        return translateSentence;

    for(let i = 0; i < sentence.length; i++) {
        let char = sentence.charAt(i);

        if(char === '&') {

            copyChar = false;
        }

        if(copyChar === true) {

            translateSentence += char;
        }

        if(copyChar === false) {

            if(char === ';'){

                unicodeWord += ';';
                translateSentence += decodeUncodeMap[unicodeWord];
                unicodeWord = '';
                copyChar = true
            } else {

                if(char !== '#' && char !== '&'){
    
                    unicodeWord += char;
                }
            }

        }
    }

    return translateSentence;
}

export function translateHtmlUnicodeToChar(sentence: string) {
    
    let i = 0;
    let iStart = 0;
    let iEnd = 0;
    let delimiterStart = '&#';
    let delimiterEnd = ';';
    let translateSentence = '';
    let translateSection = '';
    
    if(!sentence || sentence === '') {

        return '';
    }

    while(i < sentence.length) {
        iStart = sentence.indexOf(delimiterStart, i);
        iEnd = sentence.indexOf(delimiterEnd, iStart);

        if(iStart === -1 || iEnd === -1) {

            translateSentence += sentence.substring(i);
            break;
        }

        let code = sentence.substring(iStart + 2, iEnd);
        
        translateSection = String.fromCharCode(+code);

        if(i < iStart) {
            translateSentence += sentence.substring(i, iStart);
        }
        translateSentence += translateSection;  
        i = iEnd + 1;
    }

    return translateSentence;
}

export default translateHtmlUncodeToChar;