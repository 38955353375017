import axios from "axios";
import index from '../config/index';

const BASE_URL = index.baseApiUrlCitizenSticker + '/' + 'citizenSticker';

export default axios.create({
    baseURL: index.baseApiUrlCitizenSticker
});

export const axiosPrivate = axios.create({
    baseURL: index.baseApiUrlCitizenSticker,
});

export const axiosCitizenSticker = axios.create({
    baseURL: BASE_URL,
});