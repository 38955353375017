import { IModalOtp, IOtpForm } from "../../interface/modalOtp.interface";
import { OtpStatus } from "../../types/OtpStatus";

export const initModalOtp: IModalOtp = {
    isOtpModalOpen: false,
    modalTitle: '',
    portalAction: '',
    otpCode: '',
    otpStatus: OtpStatus.PERMIT_OR_REQUEST_DETAILS,
    isPermitCanceled: false,
    permitDateValidation: '',
    requestStatus: -1,
}

export const initOtpForm: IOtpForm = {
    personId: '', 
    vehicleNum: '', 
    otpCode: '', 
    portalAction: '', 
    sendOtpCodeVia: 'EMAIL',
    isPermit: false,
}