import { useProjectConfigContext } from "../contexts/ProjectConfigContext";
import { axiosCitizenSticker } from "./api";

export default function useOtpApi(){

    const { authState } = useProjectConfigContext();
    
    async function checkOtpCodeApi(formData){
    
    
        const config = {
            headers: { Authorization: `Bearer ${authState.token}` }
        };

        const reqToSend = JSON.parse(JSON.stringify(formData));
     
        const response = await axiosCitizenSticker.post('/check-otp-code', reqToSend, config);
        return await response.data;
    }

    async function cancelPermitOtpApi(formData){
    
    
        const config = {
            headers: { Authorization: `Bearer ${authState.token}` }
        };

        const reqToSend = JSON.parse(JSON.stringify(formData));
     
        const response = await axiosCitizenSticker.post('/cancel-permit', reqToSend, config);
        return await response.data;
    }

    async function addFileToRequestOtpApi(formData){
    
    
        const config = {
            headers: { Authorization: `Bearer ${authState.token}` }
        };

        const reqToSend = JSON.parse(JSON.stringify(formData));
     
        const response = await axiosCitizenSticker.post('/add-file-to-request', reqToSend, config);
        return await response.data;
    }

    async function checkRequestStatusOtpApi(formData){
    
    
        const config = {
            headers: { Authorization: `Bearer ${authState.token}` }
        };

        const reqToSend = JSON.parse(JSON.stringify(formData));
     
        const response = await axiosCitizenSticker.post('/check-request-status', reqToSend, config);
        return await response.data;
    }

    async function checkPermitValidationDateOtpApi(formData){
    
    
        const config = {
            headers: { Authorization: `Bearer ${authState.token}` }
        };

        const reqToSend = JSON.parse(JSON.stringify(formData));
     
        const response = await axiosCitizenSticker.post('/check-permit-validation-date', reqToSend, config);
        return await response.data;
    }

    async function sendOtpCodeApi(formData){
    
        const config = {
            headers: { Authorization: `Bearer ${authState.token}` }
        };

        const reqToSend = JSON.parse(JSON.stringify(formData));
        return await axiosCitizenSticker.post('/send-otp-code', reqToSend, config);
    }

    return {sendOtpCodeApi, checkOtpCodeApi, cancelPermitOtpApi, 
        checkPermitValidationDateOtpApi, checkRequestStatusOtpApi, addFileToRequestOtpApi};
}