import * as yup from 'yup';
import { REQUIRED_FIELD_MESSAGE } from './errorMessages';

export const otpFormSchema = yup.object().shape({
    personId: yup.string()
        .nonNullable()
        .required(REQUIRED_FIELD_MESSAGE),
    vehicleNum: yup.string()
        .nonNullable()
        .required(REQUIRED_FIELD_MESSAGE),
})