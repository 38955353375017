import React from 'react';

type Props = {
    title: string
}

const SectionTitle = ({ title }: Props) => {

    return (
        <div className='row'>
            <div style={{ fontSize: '1.35rem', fontWeight: '500', marginBottom: '0.25rem', textDecorationLine: 'underline' }}>{title}</div>
        </div>
    );
}

export default SectionTitle;