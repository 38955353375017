import { IPortalConfig, IProjectConfig } from '../../interface/config/IConfig.interface';

export const initPortalConfigData: IPortalConfig = {
    hasAddFileToRequestOption: false,
    hasCancelPermitOption: false,
    hasCheckRequestStatusOption: false,
    hasCheckValidityPeriodOption: false,
    hasNewRequestOption: true,
}

export const initProjectConfigData: IProjectConfig = {
    projectId: -1,
    vehicleGroups: [],
    colors: [],
    makers: [],
    streets: [],
    tdsConfig: {
        gilHaZahavAge: false,
        colorAndType: false,
        gilHaZahavOption: false,
        studentOption: false,
        gilHaZahavPayment: false,
        gilHaZahavValidityPeriod: false,
        payment: false,
        studentPayment: false,
        studentValidityPeriod: false,
        validityPeriod: false,
        handicappedOption: false,
    },
    portalConfig: initPortalConfigData,
    hasSession: false,
    hasTopDeclaration: false,
    hasAdditionalAddress: false,
    extraFile: false,
    rantKiriaBialikFile: false,
    contryClubFile: false,
    extraApartmentFiles: false,
    extraBusinessFiles: false,
    cityHebName: '',
    cityLogoPath: '',
}