import { IProjectConfig } from "../interface/config/IConfig.interface";

export const PROJECT_CONFIG_ACTION = {
    SET_PROJECT_ID: 'SET_PROJECT_ID',
    SET_DATA: 'SET_DATA',
}

export function projectConfigReducer(state: IProjectConfig, action) {

    switch (action.type) {
        case PROJECT_CONFIG_ACTION.SET_PROJECT_ID:
            return { ...state, projectId: action.payload.projectId };
        case PROJECT_CONFIG_ACTION.SET_DATA:

            const data = action.payload.data;
            const tdsConfig = data.taveiDayarSiteConfig;
            const projectConfig = data.projectConfig;
            const hasAdditionalPersonIdFile = action.payload.hasAdditionalPersonIdFile;

            return {
                ...state,
                vehicleGroups: data.vehicleGroups,
                colors: data.colors,
                makers: data.makers,
                streets: data.streets,
                tdsConfig: tdsConfig,
                cityLogoPath: projectConfig.cityLogUrl,
                cityHebName: projectConfig.hebName,
                hasTopDeclaration: false,
                hasTakanon: false,
                hasAdditionalAddress: data.hasAdditionalAddress,
                extraFile: false,
                hasSerantKiriaBialikFilession: false,
                contryClubFile: false,
                extraApartmentFiles: false,
                extraBusinessFiles: false,
                hasAdditionalPersonIdFile: hasAdditionalPersonIdFile,
                portalConfig: projectConfig.portalConfig
            };
        default:
            return state;
    }
}
