import React from 'react';
import style from '../homePage.module.css';

const ActionBox = ({onClick, title, icon}) => {
  return (
    <div className={style.actionBox} onClick={onClick}>
        <div className={style.actionBoxIcon}>
            {icon}
        </div>
        <div className={style.actionBoxTitle}>
            {title}
        </div>
    </div>
  )
}

export default ActionBox;