import React, { useReducer } from "react";
import { ComponentInfoActionKind, componentInfoReducer } from "../reducers/componentInfoReducer";
import {initComponentInfo} from '../data/initialize/componentInfo.data';
import { AlertColors } from "../types/colors/alertColors";
import { IComponentInfo } from "../interface/componentInfo.interface";

export const useComponentInfo = () => {

    const [componentInfo, componentInfoDispatch] = useReducer(componentInfoReducer, initComponentInfo as IComponentInfo);

    function setAlert(alertColor: AlertColors, hasAlert: boolean, message: string) {

        componentInfoDispatch({
            type: ComponentInfoActionKind.SET_ALERT,
            payload: {
                alertColor: alertColor,
                hasAlert: hasAlert,
                message: message
            }
        });
    }

    function setDangerAlert(message: string) {

        componentInfoDispatch({
            type: ComponentInfoActionKind.SET_ALERT,
            payload: {
                alertColor: AlertColors.DANGER,
                hasAlert: true,
                message: message
            }
        });
    }

    function setRemoveAlert() {

        componentInfoDispatch({
            type: ComponentInfoActionKind.REMOVE_ALERT,
        });
    }

    function loading() {

        componentInfoDispatch({
            type: ComponentInfoActionKind.LOADING,
        });
    }

    function notLoading() {

        componentInfoDispatch({
            type: ComponentInfoActionKind.NOT_LOADING,
        });
    }
 
    return {componentInfo, setAlert, setDangerAlert, setRemoveAlert, loading, notLoading};
}