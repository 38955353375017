import React from 'react';
import GeneralModal from '../UI/modal/GeneralModal';
import Button from '../buttons/Button';
import { FaCheck } from "react-icons/fa6";
import CancelBtn from '../buttons/CancelBtn';

let btnStyle = { width: '5rem' };

const ConfirmModal = ({ title, body, closingFunc, apporveFunc, cancelFunc }) => {

  return (
    <GeneralModal
      closingFunc={closingFunc}
      title={title}
      body={body}
      footer={(
        <>
          <CancelBtn style={btnStyle} className='btn btn-secondary' onClick={cancelFunc}>ביטול</CancelBtn>
          <Button theme={'success'} endIcon={<FaCheck />} style={btnStyle} className='btn btn-success' onClick={e => apporveFunc(e)}>אישור</Button>
        </>
      )}
    />
  )
}

export default ConfirmModal;