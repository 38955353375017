import React from 'react';
import RadioBtnsContainer from '../../../../components/form/radio/RadioBtnsContainer';
import PermitType from '../../../../types/PermitType';
import RadioOutlineBtn from '../../../../components/form/radio/RadioOutlineBtn';

type Props = {
  permitType: number, 
  handleClick: () => void
}

const DisabilityCardPermit = ({permitType, handleClick}: Props) => {

  const label = `בעל תו נכה?`;

  return (
    <div className='row'> 
        <div className='col col-sm-4'>
            <RadioBtnsContainer label={label}>
                <RadioOutlineBtn 
                isChecked={permitType !== PermitType.HANDICAPPED}
                label='לא'
                value={PermitType.REGULAR}
                onClick={handleClick}
                id="disabilityCardYes"
                name='disabilityCard'
                />
                <RadioOutlineBtn
                isChecked={permitType === PermitType.HANDICAPPED}
                label='כן' 
                value={PermitType.HANDICAPPED}
                onClick={handleClick}
                id="disabilityCardNo"
                name='disabilityCard'
                />
            </RadioBtnsContainer>
        </div>
    </div>
  );
}

export default DisabilityCardPermit;