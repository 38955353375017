import React from 'react';
import Alert from './Alert';
import { AlertColors } from '../../types/colors/alertColors';

type Props = {
    message: string, 
    isShow: boolean, 
    onClose?: () => void, 
    subject?: string
}

const DangerAlert = ({ message, isShow, onClose, subject }: Props) => {

    return <Alert type={AlertColors.DANGER}
        message={message}
        isShow={isShow}
        onClose={onClose}
        subject={subject}
    />;
}

export default DangerAlert;