import * as yup from 'yup';
import { MAX_FILE_SIZE, MAX_FILE_SIZE_MESSAGE, ONLY_DIGIT_PERMITED_MESSAGE, REQUIRED_FIELD_MESSAGE, SUPPORTED_FORMATS, TYPE_FILE_MESSAGE } from "../errorMessages";
import { onlyNumbersRegExp } from '../../helpers/regExps';

export const yupObjectCar = {

    carId: yup.string()
        .matches(onlyNumbersRegExp, ONLY_DIGIT_PERMITED_MESSAGE)
        .min(5, 'חייב להכיל לפחות 5 ספרות')
        .max(8, 'לכל היותר 8 ספרות')
        .required(REQUIRED_FIELD_MESSAGE),
    vColor: yup.string()
        .when("hasColorAndType", (hasColorAndType, schema) => {

            if (Array.isArray(hasColorAndType) && hasColorAndType.length > 0) {

                const hasColorAndTypeBool = hasColorAndType[0];

                if (hasColorAndTypeBool === false) {
                    return schema.notRequired();
                }
            }

            return (
                schema
                    .notOneOf([-1, '-1'], 'אנא בחר צבע')
                    .required(REQUIRED_FIELD_MESSAGE)
            );
        }),
    vType: yup.string()
        .when("hasColorAndType", (hasColorAndType, schema) => {

            if (Array.isArray(hasColorAndType) && hasColorAndType.length > 0) {

                const hasColorAndTypeBool = hasColorAndType[0];

                if (hasColorAndTypeBool === false) {
                    return schema.notRequired();
                }
            }

            return (
                schema
                    .notOneOf([-1, '-1'], 'אנא בחר דגם רכב')
                    .required(REQUIRED_FIELD_MESSAGE)
            );
        }),
    scanCarId: yup.mixed()
        .nonNullable()
        .required(REQUIRED_FIELD_MESSAGE)
        .test(
            "FILE_SIZE",
            MAX_FILE_SIZE_MESSAGE,
            (value) => {

                return !value || (value && value.size <= MAX_FILE_SIZE)
            }
        ).test(
            "FILE_FORMAT",
            TYPE_FILE_MESSAGE,
            (value) => {

                return !value || (value && SUPPORTED_FORMATS.includes(value?.contentType))
            }
        ),
    scanWorkStatment: yup.mixed()
        .when("ownershipCarType", (ownershipCarType, schema) => {

            if (ownershipCarType == 1 || ownershipCarType == '1') {
                return schema.notRequired();
            }

            return (
                schema
                    .nonNullable()
                    .required(REQUIRED_FIELD_MESSAGE)
                    .test(
                        "FILE_SIZE",
                        MAX_FILE_SIZE_MESSAGE,
                        (value) => {

                            return !value || (value && value.size <= MAX_FILE_SIZE)
                        }
                    )
                    .test(
                        "FILE_FORMAT",
                        TYPE_FILE_MESSAGE,
                        (value) => {

                            return !value || (value && SUPPORTED_FORMATS.includes(value?.contentType))
                        }
                    )
            );
        }),
}