import React from "react";
import translateErrorCode from "../helpers/errors/translateErrorCode";
import { axiosCitizenSticker } from "./api";
import { useProjectConfigContext } from "../contexts/ProjectConfigContext";
import { IApiError } from "../interface/apiError.interface";
import { IPermitRequest } from "../interface/permitRequest.interface";

export interface IResponseAddPermitRequest {
    success: boolean,
    asmachta: string;
    permitRequestId: number;
    amountPayed: number;
    receiptUrl: string;
    hasReceipt: boolean;
    apiError: IApiError;
}

const initResponseAddPermitRequest: IResponseAddPermitRequest = {
    success: false,
    asmachta: '',
    permitRequestId: -1,
    amountPayed: 0,
    receiptUrl: '',
    hasReceipt: false,
    apiError: {
        displayMessage: '',
        errorCode: 0,
        errorName: ''
    }
}

export default function useAddPermitRequestApi() {

    const { authState } = useProjectConfigContext();

    async function addPermitRequestApi(formData: IPermitRequest): Promise<IResponseAddPermitRequest> {

        const responseAddPermitRequest: IResponseAddPermitRequest = { ...initResponseAddPermitRequest };

        try {

            const config = {
                headers: { Authorization: `Bearer ${authState.token}` }
            };

            const reqToSend = JSON.parse(JSON.stringify(formData));
           
            const response = await axiosCitizenSticker.post('/addCitizenSticker', reqToSend, config);
            const data = await response.data;

            if (!data.permitRequestId || data.permitRequestId <= 0)
                throw Error('Unknown problem returned from server');

            responseAddPermitRequest.asmachta = data.asmachta ? data.asmachta : '';
            responseAddPermitRequest.permitRequestId = data.permitRequestId ? data.permitRequestId : -1;
            responseAddPermitRequest.amountPayed = data.payment ? data.payment : 0;
            responseAddPermitRequest.receiptUrl = data.receiptUrl ? data.receiptUrl : '';
            responseAddPermitRequest.hasReceipt = data.hasReceipt ? data.hasReceipt : false;
            responseAddPermitRequest.success = true;

            return responseAddPermitRequest;
        } catch (error) {
            console.log('Error', error);
            let errorMsg: string = 'בעיה כללית בשרת';
            let errorCode = 1;
            let errorName = '';

            if (error.response) {

                const data = error.response.data;

                errorMsg = translateErrorCode(error, {});
                /*
                    errorMsg = data.message ? data.message : 'בעיה כללית בשרת';
                    errorCode = data.errorCode ? data.errorCode : 1;
                    errorName = data.errorName ? data.errorName : '';
    
                    if (errorCode === 1) {
                        errorMsg = 'כבר קיים תו במערכת';
                    } else if (errorCode === 2) {
                        errorMsg = 'קיימת כבר בקשה עם הנתונים';
                    }else if (errorCode === 14) {
                        errorMsg = 'בעיה בתהליך הסליקה - לא כל הנתונים התקבלו להשלמת התהליך';
                    }else if (errorCode === 13) {
                        errorMsg = 'בעיה בתהליך הסליקה - הבקשה לא התקבלה';
                    }else if (errorCode === 12) {
                        errorMsg = 'התרחשה בעיה בתהליך עיבוד הקבצים - הבקשה לא התקבלה';
                    }else if (errorCode === 11) {
                        errorMsg = `בעיה בתהליך הסליקה - בעיה בתהליך סליקת כרטיס האשראי. אנא בדוק תקינות כרטיס האשראי. במידת הצורך, אנא פנה לחברת האשראי לצורך בדיקה`
                    }else if (errorCode === 5) {
                        errorMsg = 'נוצרה בעיה בגלל הנתונים השתקבלו';
                    }else if (errorCode === 4) {
                        errorMsg = 'בעיה כללית בשרת - הבקשה לא התקבלה';
                    }else if (errorCode === 3) {
                        errorMsg = 'התרחשה בעיה בתהליך עיבוד הקבצים - הבקשה לא התקבלה';
                    }
                */


                console.log(`Error - ${errorMsg}`);
            } else if (error.request) {
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

            responseAddPermitRequest.apiError = {
                displayMessage: errorMsg,
                errorCode: errorCode,
                errorName: errorName
            }

            return responseAddPermitRequest;
        }
    }

    return addPermitRequestApi;
}