import React from 'react';
import { useHomePageContext } from '../../../../../contexts/HomePageContext';
import RequestStatusType from '../../../../../types/RequestStatusType';
import Button from '../../../../../components/buttons/Button';

const DisplayRequestStatus = () => {

  const { modalOtpState, closeOtpModal } = useHomePageContext();
  const requestStatus = modalOtpState.requestStatus;

  let text = <p>אין נתונים</p>;

  if(RequestStatusType.APPROVED === requestStatus) {
    text = <p>הבקשה אושרה.</p>;
  } else if(RequestStatusType.PENDING === requestStatus) {
    text = (
      <p>
      הבקשה ממתינה לאישור.
      <br/>
      לאחר אישור הבקשה ישלח מייל אישור.
      </p>
      );
  } else if(RequestStatusType.REJECTED === requestStatus) {
    text = <p>הבקשה נדחתה.</p>;
  }

  return (
    <div className='row'>
      <div className='col-12 mb-2'>{text}</div>
      <div className='col-12'><Button onClick={closeOtpModal} text='אישור'/></div>
    </div>
  );
}

export default DisplayRequestStatus;