import React, { useState } from 'react';
import InputGroup from '../../../../../components/form/InputGroup';
import { useHomePageContext } from '../../../../../contexts/HomePageContext';
import { MdOutlineNoteAdd } from "react-icons/md";
import Button from '../../../../../components/buttons/Button';
import { MAX_FILE_SIZE, MAX_FILE_SIZE_MESSAGE, SUPPORTED_FORMATS, TYPE_FILE_MESSAGE } from '../../../../../validations/errorMessages';

const AddFileForm = () => {

  const { handleAddFileOtp } = useHomePageContext();
  const [fileToAdd, setFileToAdd] = useState('');
  const [error, setError] = useState('');

  function handleChengeAddFile(event) {

    const file = event.currentTarget.files[0];

    if (!file) {
      setError('לא נבחר קובץ');
      return;
    }

    if (file?.size && file.size > MAX_FILE_SIZE) {
      setError(MAX_FILE_SIZE_MESSAGE);
      return;
    }

    if (!SUPPORTED_FORMATS.includes(file?.type)) {
      setError(TYPE_FILE_MESSAGE);
      return;
    }

    setError('');
    setFileToAdd(file);
  }

  return (
    <div>

      <div className='row mb-2'>
        <div className='col-12 col-sm-8'>
          <InputGroup
            label='לצרף קובץ/מסמך לבקשה קיימת:'
            handleChange={handleChengeAddFile}
            value={fileToAdd}
            type='file'
            errorMsg={error !== '' ? error : undefined}
            name='addFileName'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-12 col-sm-6'>
          <Button theme='success' onClick={() => handleAddFileOtp(fileToAdd)} endIcon={<MdOutlineNoteAdd />} text='צרף קובץ' />
        </div>
      </div>

    </div>
  )
}

export default AddFileForm;