import { initConfirmationModal } from "../data/initialize/confirmationModal.data";
import { IConfirmationModal } from "../interface/confirmationModal.interfase";


export enum SuccessAddRequestModalActionKind {
    CLOSE_MODAL = 'CLOSE_MODAL',
    OPEN_MODAL = 'OPEN_MODAL',
    UPDATE_DATA = 'UPDATE_DATA',
}

// An interface for our actions
type SuccessAddRequestModalAction = {

    type: SuccessAddRequestModalActionKind.OPEN_MODAL
} | {

    type: SuccessAddRequestModalActionKind.CLOSE_MODAL;
} | {

    type: SuccessAddRequestModalActionKind.UPDATE_DATA;
    asmachta: string;
    permitRequestId: number,
    amountPayed: number,
    isModalOpen: boolean,
    hasReceipt: boolean,
    receiptUrl: string,
};


export function successAddRequestModalReduser(state: IConfirmationModal, action: SuccessAddRequestModalAction) {

    switch (action.type) {

        case SuccessAddRequestModalActionKind.OPEN_MODAL: {

            return state;
        }
        case SuccessAddRequestModalActionKind.CLOSE_MODAL: {

            return {
                ...state,
                asmachta: initConfirmationModal.asmachta,
                permitRequestId: initConfirmationModal.permitRequestId,
                amountPayed: initConfirmationModal.amountPayed,
                hasReceipt: initConfirmationModal.hasReceipt,
                receiptUrl: initConfirmationModal.receiptUrl,
                isModalOpen: false,
            };
        }
        case SuccessAddRequestModalActionKind.UPDATE_DATA: {

            return {
                ...state,
                asmachta: action.asmachta,
                permitRequestId: action.permitRequestId,
                amountPayed: action.amountPayed,
                isModalOpen: action.isModalOpen,
                hasReceipt: action.hasReceipt,
                receiptUrl: action.receiptUrl,
            };
        }
        default: {

            return state;
        }
    }
}