import { axiosCitizenSticker } from '../api/api';
import {calcPaymentAmountApi} from '../api/calcPaymentAmountApi'
import { useProjectConfigContext } from '../contexts/ProjectConfigContext';

const useCalculatePaymentAmount = () => {

    const { authState } = useProjectConfigContext();

    async function calculatePaymentAmount(permitType, streetId) {

        const config = {
            headers: { Authorization: `Bearer ${authState.token}` }
        };

        const reqToSend = {
            permitType: permitType, 
            streetId: streetId
        };

        console.log(`reqToSend: ${JSON.stringify(reqToSend)}`);
        const response = await axiosCitizenSticker.post(calcPaymentAmountApi, reqToSend, config);

        return await response.data;
    }

    return calculatePaymentAmount;
}

export default useCalculatePaymentAmount;