import { useState } from "react";
import Alert from "../../../components/Alerts/Alert";
import { useProjectConfigContext } from "../../../contexts/ProjectConfigContext";

export default function TopDeclaration() {

    const { projectConfigState } = useProjectConfigContext();
    const [showAlert, setShowAlert] = useState(true);

    const projectId = projectConfigState.projectId;
    let message = '';

    if (projectId == 26) {

        message = (
            <p>
                <span>1. התו יכנס לתוקף תוך 24 שעות מקבלת האישור במייל.</span>
                <br />
                <span>2. תושב/ת יקר/ה תו תושב ללא עלות רק מגיל 67.</span>
                <br />
                <span>3. תו התושב יאושר רק לרכב פרטי מסוג M1.</span>
            </p>
        );

    } else if (projectId == 51) {

        message = (
            <div>
                <span>1. אישור לתו יינתן בתום תשלום האגרה, והשלמת הגשת כל המסמכים הנדרשים.</span>
                <br />
                <span>2. התו יכנס לתוקף תוך 24 שעות מקבלת האישור.</span>
            </div>
        );

    }

    return (
        <Alert type={'secondary'}
            message={(
            <div>
                מלאו את הפרטים הבאים בצורה מפורטת שיסייעו לנו בטיפול בפנייה. <span style={{ color: 'red' }}>*</span>שדות חובה.
                {message}
            </div>
            )}
            isShow={showAlert}
            onClose={() => setShowAlert(false)}
            subject={'הודעה'}
        />
    );
}
