import { ServerExceptionTypes } from "../../types/ServerExceptionTypes";

/**
 * 
 * @param {object} error - Axios error object, need to contain response property, the response need to contain status(number) and data.
 * Data - timestamp(string), message(string), errorCode(number), errorName(string).
 * @param {object} messageDescrption - An object containing an errors messages to overriding the default.
 * @returns {string} message to display in the GUI.
 */
export default function translateErrorCode(error, messageDescrption = { generalError: 'בעיה כללית בשרת' }) {
    let message = messageDescrption.generalError ? messageDescrption.generalError : 'בעיה כללית בשרת';
    const data = error.response.data;

    if (data.errorCode === ServerExceptionTypes.USER_NOT_FOUND) {

        message = messageDescrption.USER_NOT_FOUND ? messageDescrption.USER_NOT_FOUND : 'כניסה למערכת נכשלה - אחד או יותר מהנתונים לא נכון!';
    } else if (data.errorCode === ServerExceptionTypes.USER_NO_IN_PARKING) {

        message = messageDescrption.USER_NO_IN_PARKING ? messageDescrption.USER_NO_IN_PARKING : 'כניסה למערכת נכשלה - המשתמש לא הוקם בחניה!';
    } else if (data.errorCode === ServerExceptionTypes.USER_DOES_NOT_HAVE_THE_NEEDED_CREDENTIAL) {

        message = messageDescrption.USER_DOES_NOT_HAVE_THE_NEEDED_CREDENTIAL ? messageDescrption.USER_DOES_NOT_HAVE_THE_NEEDED_CREDENTIAL : 'כניסה למערכת נכשלה - למשתמש אין את ההרשאה המתאימה!';
    } else if (data.errorCode === ServerExceptionTypes.BAD_REQUEST) {

        message = messageDescrption.BAD_REQUEST ? messageDescrption.BAD_REQUEST : 'לא נשלחו כל הנתונים לשרת';
    } else if (data.errorCode === ServerExceptionTypes.VALID_PERMIT_ALREADY_EXIST) {

        message = messageDescrption.VALID_PERMIT_ALREADY_EXIST ? messageDescrption.VALID_PERMIT_ALREADY_EXIST : 'קיים במערכת תו עם מספר רכב ותעודת זהות זהים';
    } else if (data.errorCode === ServerExceptionTypes.PENDING_REQUEST_ALREADY_EXIST) {

        message = messageDescrption.PENDING_REQUEST_ALREADY_EXIST ? messageDescrption.PENDING_REQUEST_ALREADY_EXIST : 'קיימת בקשה שממתינה לאישור עם מספר רכב ותעודת זהות זהים';
    } else if (data.errorCode === ServerExceptionTypes.OTP_CODE_VERIFICATION_FAILED) {

        message = messageDescrption.OTP_CODE_VERIFICATION_FAILED ? messageDescrption.OTP_CODE_VERIFICATION_FAILED : 'קוד אימות נכשל, צריך להתחבר מחדש';
    } else if (data.errorCode === ServerExceptionTypes.TRANZILA_PAYMENT_PROCESS_FAIL) {

        message = messageDescrption.TRANZILA_PAYMENT_PROCESS_FAIL ? messageDescrption.TRANZILA_PAYMENT_PROCESS_FAIL : (
            <>
                בעיה בתהליך סליקת כרטיס האשראי. אנא בדוק תקינות כרטיס האשראי.
                <br />
                במידת הצורך, אנא פנה לחברת האשראי לצורך בדיקה
            </>);
    } else if (data.errorCode === ServerExceptionTypes.ENABLE_PAYMENT_TRANSFER) {

        message = messageDescrption.ENABLE_PAYMENT_TRANSFER ? messageDescrption.ENABLE_PAYMENT_TRANSFER : 'תהליך התשלום נכשל - לא ניתן לשלם באותו הכרטיס בטווח של פחות מ12 שעות.';
    } else if (data.errorCode === ServerExceptionTypes.FILES_NOT_VALID) {

        message = messageDescrption.FILES_NOT_VALID ? messageDescrption.FILES_NOT_VALID : 'תהליך נכשל - התרחשה בעיה בתהליך העלאת הקבצים.';
    } else if (data.errorCode === ServerExceptionTypes.GENERAL_PAYMENT_ERROR) {

        message = messageDescrption.GENERAL_PAYMENT_ERROR ? messageDescrption.GENERAL_PAYMENT_ERROR : 'תהליך נכשל - התרחשה בעיה בתהליך התשלום, יש לפנות לעזרה.';
    } else if (data.errorCode === ServerExceptionTypes.REQUESTED_DATA_IS_NOT_FOUND) {

        message = messageDescrption.REQUESTED_DATA_IS_NOT_FOUND ? messageDescrption.REQUESTED_DATA_IS_NOT_FOUND : 'המידע המבוקשה אינו קיים';
    } else if (data.errorCode === ServerExceptionTypes.INVALID_OR_NOT_EXIST_EMAIL_IN_SYSTEM) {

        message = messageDescrption.INVALID_OR_NOT_EXIST_EMAIL_IN_SYSTEM ? messageDescrption.INVALID_OR_NOT_EXIST_EMAIL_IN_SYSTEM : 'האימייל שנמצא במערכת אינו תקין או לא קיים';
    } else if (data.errorCode === ServerExceptionTypes.INVALID_OR_NOT_EXIST_MOBILE_IN_SYSTEM) {

        message = messageDescrption.INVALID_OR_NOT_EXIST_MOBILE_IN_SYSTEM ? messageDescrption.INVALID_OR_NOT_EXIST_MOBILE_IN_SYSTEM : 'מספר הנייד שנמצא במערכת אינה תקין או לא קיים';
    }

    console.log(error.response.data);
    return message;
} 