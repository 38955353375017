import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom'
import NotFound from './pages/NotFound';
import Footer from './components/Footer/Footer';
import Style from './App.module.css';
import Page from './components/UI/page/Page';
import Header from './components/header/Header';
import { HomePageProvider } from './contexts/HomePageContext';
import { PermitRequestProvider } from './contexts/PermitRequestContext';

function App() {

  let page = (
    <Routes>
      <Route exact path="/" element={<NotFound />} />
      <Route path='/home/:projectId' element={<HomePageProvider />} />
      <Route exact path="/permit-request/:projectId" element={<PermitRequestProvider />}/>
      <Route path='*' element={<NotFound />} />
    </Routes>
  );

  return (
    <div className={Style.App}>
      <Header />
      <Page> {page} </Page>
      <Footer />
    </div>
  );
}

export default App;
