
enum PermitType {

    ALL = -1,
    REGULAR = 0,
    GOLDEN = 1,
    STUDENT=  2,
    HANDICAPPED = 3,
    MUNICIPAL_EMPLOYEE = 4,
}

export default PermitType;