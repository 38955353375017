import React from 'react';
import Modal from './Modal';
import style from './modal.module.css';
import GeneralModalClosingBtn from '../../buttons/GeneralModalClosingBtn';

const GeneralModal = ({ title, body, footer, closingFunc, hasHeader, hasFooter, modalSize }) => {

    let headerClasses = style.header;
    let bodyClasses = style.modalBody;
    let footerClasses = style.footer;
    let bodyStyle = { maxHeight: '80vh' };

    let amountToReduced = 0;

    if(hasHeader) amountToReduced += 6;

    if(hasFooter) amountToReduced += 8;

    let bodyHeight = 80 - amountToReduced;

    bodyStyle.maxHeight = `${bodyHeight}vh`;

    if(modalSize === 'L' || modalSize === 'XL') {
        bodyStyle.height = `${bodyHeight}vh`;
    }
    
    return (
        <Modal modalSize={modalSize}>  
            {hasHeader ? (
            <div className={headerClasses}>
                <span></span>
                <span className={style.modalTitle}>{title}</span>
                <GeneralModalClosingBtn onClick={closingFunc}/>
            </div>
            ): ''}
            <div style={bodyStyle} className={bodyClasses}> {body} </div>
            {hasFooter ? (
            <>
                <div className={style.hr} />
                <div className={footerClasses}> {footer} </div>
            </>
            ): ''}

        </Modal>
    );

}

GeneralModal.defaultProps = {
    hasHeader: true,
    hasFooter: true,
    footer: '',
    title: '',
    content: '',
    modalSize: 'S'
};

export default GeneralModal;