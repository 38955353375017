import React from 'react';

const PageTitle = () => {
    const title = 'טופס בקשה לתו חניה';

  return (
    <div className='mb-4'>
        <h1>{title}</h1>
    </div>
  )
}

export default PageTitle;