import React from 'react';
import translateHtmlUncodeToChar from '../../../helpers/language/TranslateHtmlUncodeToChar';
import { useProjectConfigContext } from '../../../contexts/ProjectConfigContext';
import SelectWithSearch from './SelectWithSearch';

const SelectCarColor = ({ name, handleBlur, handleChange, errorMsg, value }) => {

    const { projectConfigState } = useProjectConfigContext();
    const colorList = projectConfigState.colors;
    let colors = colorList.map(color => {

        return { label: translateHtmlUncodeToChar(color.name), value: color.id }
    });

    colors = [{ value: -1, label: 'צריך לבחור' }, ...colors];

    const valueStr = colors.findLast(option => option.value == value);

    return (
        <SelectWithSearch
            errorMsg={errorMsg}
            name={name}
            label={'צבע רכב'}
            value={valueStr}
            isRequired={true}
            selects={colors}
            handleBlur={handleBlur}
            handleChange={handleChange} />
    )
}

export default SelectCarColor